import { PortableTextBlock } from '@portabletext/types'
import { bind, Subscribe } from '@react-rxjs/core'
import React, { VFC } from 'react'
import { of, switchMap } from 'rxjs'

import { SanityDispensaryBio } from '../../../graphql/gatsby'
import useIsSsr from '../../../hooks/useIsSsr'
import { useLogAndCaptureLeftResult } from '../../../hooks/useLogAndCaptureLeftResult'
import { useResultWithLeftEffect } from '../../../hooks/useResultWithLeftEffect'
import { useDispensaryContext } from '../../../lib/dispensaries'
import { dispensaryImageUrlFor, useSanityRoleBasedContent } from '../../../lib/sanity'
import {
  latestDispensaryContact$,
  useDispensaryContact,
} from '../../../lib/sanity-dispensaries/contact'
import {
  dispensariesSiteSettingsCdn$,
  sanityDispensaryCdn$,
} from '../../../lib/sanity-dispensaries/query'
import { latestDispensarySlug$ } from '../../../lib/sanity-dispensaries/state'
import { constNull } from '../../../utils/constant'
import { pluckData } from '../../../utils/result'
import { mapRight } from '../../../utils/rx/operators'
import { DispensaryContactCard } from '../../dispensaries/contact-card/DispensaryContactCard'
import Spinner from '../../Spinner'
import { serializers } from './serializers'
import * as Styled from './styled'

export interface DispensaryBioProps extends SanityDispensaryBio {
  className?: string
}

// to ensure <Subscribe /> acts as suspense boundary, but not render anything
const VoidFallback: VFC = () => null

const defaultBio$ = dispensariesSiteSettingsCdn$.pipe(
  mapRight((siteSettingsResult) => ({
    _tag: 'Right' as const,
    data: {
      bioBlocks: siteSettingsResult.data?.defaultDispensaryBio,
      bioImage: undefined,
    },
  })),
)

function filterAndTakeUnlessEmpty<T extends PortableTextBlock>(
  blocks: ReadonlyArray<T> | null | undefined,
): Array<T> | undefined {
  // TODO: should we trim empty blocks from leading and trailing edges only?
  const filtered = blocks?.filter(
    (block) => !(block.children.length === 1 && block.children[0].text === '\n'),
  )
  return filtered && filtered.length ? filtered : undefined
}

const dispensaryBio$ = sanityDispensaryCdn$.pipe(
  mapRight((dispensaryResult) => ({
    _tag: 'Right' as const,
    data: {
      bioBlocks: filterAndTakeUnlessEmpty(dispensaryResult.data?.bio?.content),
      bioImage: dispensaryResult.data?.bio?.image,
    },
  })),
)

const dispensaryBioWithDefault$ = dispensaryBio$.pipe(
  switchMap((dispensaryBioResult) =>
    dispensaryBioResult._tag === 'Left' || dispensaryBioResult.data.bioBlocks
      ? of(dispensaryBioResult)
      : defaultBio$.pipe(
          mapRight((defaultBioResult) => ({
            _tag: 'Right' as const,
            data: {
              ...dispensaryBioResult.data,
              bioBlocks: defaultBioResult.data.bioBlocks,
            },
          })),
        ),
  ),
)

const [useDispensaryOrDefaultBio] = bind((forceDefault: boolean) =>
  forceDefault
    ? defaultBio$
    : latestDispensarySlug$.pipe(
        switchMap((dispensarySlug) => (!dispensarySlug ? defaultBio$ : dispensaryBioWithDefault$)),
      ),
)

const DispensaryBioContent: React.FC<{ forceDefault: boolean }> = ({ forceDefault }) => {
  const bioResult = useDispensaryOrDefaultBio(forceDefault)

  useLogAndCaptureLeftResult(bioResult)

  const bioImageRef = bioResult._tag === 'Right' ? bioResult.data.bioImage?.asset?._ref : undefined
  const bioBlocks = (bioResult._tag === 'Right' && bioResult.data.bioBlocks) || []

  return (
    <>
      {bioImageRef && (
        <Styled.Image
          sizes="(max-width: 599px) 100vw, (max-width: 991px): 50vw, (max-width: 1280px): 33vw, 350px"
          srcSet={[
            `${
              dispensaryImageUrlFor({ _ref: bioImageRef })
                .width(400)
                .fit('max')
                .auto('format')
                .url() || ''
            } 400w`,
            `${
              dispensaryImageUrlFor({ _ref: bioImageRef })
                .width(800)
                .fit('max')
                .auto('format')
                .url() || ''
            } 800w`,
            `${
              dispensaryImageUrlFor({ _ref: bioImageRef })
                .width(1200)
                .fit('max')
                .auto('format')
                .url() || ''
            } 1200w`,
          ].join(', ')}
          src={
            dispensaryImageUrlFor({ _ref: bioImageRef })
              .width(600)
              .fit('max')
              .auto('format')
              .url() || ''
          }
          alt={'Dispensary Bio Image'}
        />
      )}
      {bioBlocks && <Styled.BioPortableText blocks={bioBlocks} serializers={serializers} />}
    </>
  )
}

const DispensaryContact = () => {
  const dispensary = useDispensaryContext()
  const [, dispensaryContact] = useResultWithLeftEffect(
    useDispensaryContact(),
    pluckData,
    constNull,
  )

  if (!dispensary || dispensaryContact?.hideCardInDispensary) {
    return null
  }

  return (
    <Styled.ContactContainer>
      <h3>Contact Information</h3>
      <DispensaryContactCard dispensary={dispensary} contact={dispensaryContact} clickableLinks />
    </Styled.ContactContainer>
  )
}

const DispensaryBio: React.FC<DispensaryBioProps> = ({
  forceDefault,
  pageSectionStyles,
  roleBasedContent,
}) => {
  // Suspense not supported server-side yet
  const isSsr = useIsSsr()
  const { shouldDisplay } = useSanityRoleBasedContent({ roleBasedContent })

  if (!shouldDisplay || isSsr) {
    return null
  }

  return (
    <Styled.Container pageSectionStyles={pageSectionStyles}>
      <Subscribe fallback={<Spinner loading />}>
        <DispensaryBioContent forceDefault={forceDefault ?? false} />
      </Subscribe>
      <Subscribe source$={latestDispensaryContact$} fallback={<VoidFallback />}>
        <DispensaryContact />
      </Subscribe>
    </Styled.Container>
  )
}

export default DispensaryBio
