import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { useDispensaryContext } from '../../../lib/dispensaries'
import Link from '../../global/Link'
import RegistrationLink from '../../global/RegistrationLink'
import DataSheetDisclaimer from '../../shop/pdp/DataSheetDisclaimer'
import BodyPortableText from '../BodyPortableText'

const StyledDataSheetDisclaimer = styled(DataSheetDisclaimer)`
  ${BodyPortableText} {
    p,
    ul {
      font-size: inherit;
    }
  }
`

const LibraryIntroMessage = ({ libraryType }) => {
  const dispensary = useDispensaryContext()
  if (libraryType === 'professional') {
    return (
      <React.Fragment>
        <p>
          <FontAwesomeIcon className="exclamation-circle" icon={faExclamationCircle} />
          View our latest Immune Support and Acute Viral protocols here:{' '}
          <a
            href="https://www.designsforhealth.com/research-education/library/protocol/acute-viral-protocol"
            target="_blank"
            rel="noreferrer"
          >
            Acute Viral Protocol
          </a>
        </p>
        <StyledDataSheetDisclaimer />
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      <p>
        <strong>Welcome to the Designs for Health Educational Library.</strong>
      </p>
      {!dispensary && (
        <p>
          Looking for additional resources? Please <Link to="/login">login</Link> to your health
          care practitioner account to access our product technical sheets, protocols, webinars, and
          more. Don’t have a professional account yet? Register here:{' '}
          <RegistrationLink registerType="practitioner">Practitioner Registration</RegistrationLink>
        </p>
      )}
      <StyledDataSheetDisclaimer />
    </React.Fragment>
  )
}

LibraryIntroMessage.propTypes = {
  libraryType: PropTypes.oneOf(['public', 'professional']).isRequired,
}

export default LibraryIntroMessage
