import { ButtonLink } from '@designsforhealth/dfh-react-components'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import useResourceLibrary from '../../../hooks/useResourceLibrary'
import Spinner from '../../Spinner'
import LibraryBreadcrumbs from './LibraryBreadcrumbs'
import ResourceFilters from './ResourceFilters'
import ResourceList from './ResourceList'

const Library = ({ className }) => {
  const { data, loading, isProfessional, searchOptions } = useResourceLibrary()

  return (
    <div className={className}>
      {loading ? (
        <div className="spinner-loading">
          <Spinner loading />
        </div>
      ) : (
        <div className="faceted-container">
          {data && (
            <>
              <LibraryBreadcrumbs
                meta={data.meta}
                libraryType={isProfessional ? 'professional' : 'public'}
              />
              <div className="faceted-body">
                <ResourceFilters
                  libraryType={isProfessional ? 'professional' : 'public'}
                  searchOptions={searchOptions}
                  meta={data.meta}
                />
                <ResourceList
                  libraryType={isProfessional ? 'professional' : 'public'}
                  searchOptions={searchOptions}
                  meta={data.meta}
                  resources={data.resources}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

Library.defaultProps = {
  className: '',
}

Library.propTypes = {
  className: PropTypes.string,
}

const StyledLibrary = styled(Library)(({ theme }) => ({
  margin: '0',
  fontFamily: theme.fontFamilies.gotham,
  fontSize: '1rem',
  fontWeight: '400',
  lineHeight: '1.5',
  color: '#212529',
  textAlign: 'left',
  backgroundColor: theme.colors.grayscale.white,
  [`a, ${ButtonLink}`]: {
    color: theme.colors.brand.orange,
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  p: {
    marginTop: '0',
    marginBottom: '1rem',
  },
  '.display-none': {
    display: 'none !important',
  },
  '.spinner-loading': {
    background: theme.colors.grayscale.white,
    padding: '3rem !important',
  },
  '.dimmer': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: '.85',
    left: '0',
    top: '0',
    backgroundColor: 'rgba(21, 33, 48, .85)',
    zIndex: '5',
  },
  '.exclamation-circle': {
    display: 'inline-block',
    color: theme.colors.brand.gray,
    marginRight: '.35rem',
  },
  '.faceted-container': {
    position: 'relative',
  },
  '.faceted-body': {
    background: theme.colors.grayscale.white,
    color: theme.colors.brand.gray,
    width: '100%',
    paddingRight: '0',
    paddingLeft: '0',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  '@media(min-width: 768px)': {
    '.display-md-block': {
      display: 'block !important',
    },
  },
  '@media(min-width: 992px)': {
    '.display-lg-none': {
      display: 'none !important',
    },
    '.display-lg-block': {
      display: 'block !important',
    },
    '.faceted-body': {
      flexDirection: 'row',
    },
  },
}))

export default StyledLibrary
