import styled from 'styled-components'

import { pillarBox1920Styles } from '../../../lib/styles/pillar-box'
import SanityLink from '../../global/SanityLink'

export const Background = styled.div<{ backgroundColor?: string }>`
  background-color: ${({ backgroundColor, theme }) => backgroundColor ?? theme.colors.brand.white};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  overflow: hidden;
  width: 100%;
  padding: 30px 15%;
  ${pillarBox1920Styles};
  @media (min-width: 1440px) {
    flex-direction: row;
    align-items: center;
    padding-left: 192px;
    padding-right: 192px;
  }

  .slick-slider {
    width: 60%;
  }
`

export const ScrollableContainer = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
  gap: 20px;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  & > div {
    flex: 0 0 auto;
  }
`

export const Wrapper = styled.div`
  flex: 1;
  margin-bottom: 30px;
`

export const Title = styled.h1<{ textColor?: string }>`
  color: ${({ textColor, theme }) => textColor ?? theme.colors.text.primary};
  font-size: 28px;
  margin-top: 2rem;
  @media (min-width: 992px) {
    margin-left: 0;
    margin-right: 0;
  }
  @media (min-width: 1440px) {
    font-size: 32px;
  }
`

export const Subtitle = styled.p<{ textColor?: string }>`
  color: ${({ textColor, theme }) => textColor ?? theme.colors.text.primary};
  font-size: 18px;
  margin-top: 0.5rem;
  @media (min-width: 992px) {
    margin-left: 0;
    margin-right: 0;
  }
  @media (min-width: 1440px) {
    font-size: 16px;
  }
`

export const Button = styled(SanityLink)<{
  $ctaBackgroundColor?: string
  $ctaTextColor?: string
  $isMobile: boolean
}>`
  display: inline-block;
  background-color: ${({ $ctaBackgroundColor, theme }) =>
    $ctaBackgroundColor ?? theme.colors.brand.blue};
  color: ${({ $ctaTextColor, theme }) => $ctaTextColor ?? theme.colors.brand.white};
  width: fit-content;
  text-decoration: none;
  padding: 20px 50px;
  border-radius: 7px;
  font-weight: 500;
  margin-top: 20px;
  margin: 20px ${({ $isMobile }) => ($isMobile ? 'auto 0' : '0 0 0')};
`
