import { PrimaryButton } from '@designsforhealth/dfh-react-components'
import styled, { css } from 'styled-components'

import {
  Maybe,
  SanityCarouselHalfHero,
  SanityTextStyles,
  Scalars,
} from '../../../../graphql/gatsby'
import { textStylesFor } from '../../styles'

export const TextBlock = styled.div<{
  $heroImageTextLayout?: Maybe<Scalars['String']>
  $heroTextStyles?: Maybe<SanityTextStyles>
}>`
  margin-top: 32px;
  width: 60%;
  padding-left: 7%;
  padding-right: 7%;
  box-sizing: border-box;
  z-index: 1;
  h2 {
    font-family: ${({ theme }) => theme.fontFamilies.gotham};
    font-weight: 700;
    font-size: 1.25rem;
    margin: 0;
  }
  p {
    font-family: ${({ theme }) => theme.fontFamilies.gotham};
    font-weight: 500;
    font-size: 0.6875rem;
    letter-spacing: 0.055em;
    margin: 15px 0;
    overflow-wrap: break-word;
    word-break: break-word;
    line-height: 1.5;
  }
  ul,
  li {
    font-family: ${({ theme }) => theme.fontFamilies.gotham};
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.055em;
  }
  @media (min-width: 576px) {
    width: 46%;
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 0;
    h2 {
      margin-top: 0;
    }
    p,
    ul,
    li {
      font-size: 0.6875rem;
    }
  }
  @media (min-width: 768px) {
    h2 {
      font-size: 1.625rem;
    }
    p,
    ul,
    li {
      font-size: 0.75rem;
    }
  }
  @media (min-width: 992px) {
    p,
    ul,
    li {
      font-size: 0.875rem;
    }
  }
  @media (min-width: 1440px) {
    h2 {
      font-size: 2.875rem;
    }
    p,
    ul,
    li {
      font-size: 1.125rem;
    }
  }
  ${({ $heroTextStyles }) => textStylesFor($heroTextStyles)};
  ${({ $heroImageTextLayout }) => {
    switch ($heroImageTextLayout) {
      case 'backgroundImageTextLeft':
        return css`
          @media (min-width: 576px) {
            padding-right: 0;
          }
        `
      case 'backgroundImageTextRight':
        return css`
          position: absolute;
          top: 50%;
          margin-top: auto;
          padding-right: 0;
          transform: translate(50%, -50%);
          @media (min-width: 576px) {
            transform: translate(100%, -50%);
          }
        `
      case 'backgroundImageTextCenter':
        return css`
          margin: auto;
          @media (min-width: 576px) {
            padding-left: 0;
            padding-right: 7px;
          }
        `
      case 'imageLeftOfText':
        return css`
          margin-left: 50%;
          padding-right: 0;
        `
      case 'imageRightOfText':
      default:
        return css``
    }
  }}
`

export const ShortTitle = styled.h3<{ $heroImageTextLayout?: Maybe<Scalars['String']> }>`
  display: block;
  font-size: 1.1rem;
  margin-left: auto;
  @media (min-width: 576px) {
    display: none;
  }
  ${({ $heroImageTextLayout }) => {
    switch ($heroImageTextLayout) {
      case 'backgroundImageTextRight':
        return css`
          width: 80%;
        `
      default:
        return css``
    }
  }}
`

export const LongTitle = styled.h2`
  display: none;
  @media (min-width: 576px) {
    display: block;
  }
`

export const LongText = styled.div`
  display: none;
  @media (min-width: 576px) {
    display: block;
    margin: 22px 0;
  }
`

export const ShortText = styled.div<{ $heroImageTextLayout?: Maybe<Scalars['String']> }>`
  display: block;
  width: 80%;
  @media (min-width: 576px) {
    display: none;
  }
  ${({ $heroImageTextLayout }) => {
    switch ($heroImageTextLayout) {
      case 'backgroundImageTextRight':
        return css`
          margin-left: auto;
          margin-right: 0;
        `
      case 'backgroundImageTextCenter':
        return css`
          margin-left: auto;
          margin-right: auto;
        `
      default:
        return css``
    }
  }}
`

export const SlideImage = styled.div<{ $heroImageTextLayout?: Maybe<Scalars['String']> }>`
  ${({ $heroImageTextLayout }) => {
    switch ($heroImageTextLayout) {
      case 'imageLeftOfText':
        return css`
          img {
            height: 100%;
            position: absolute;
            right: 100px;
            width: 100%;
            display: block;
            object-fit: contain;
          }
          @media (min-width: 576px) {
            img {
              top: 50%;
              left: 0;
              right: 128px;
              width: 60%;
              transform: translateY(-50%) translateX(-2rem);
            }
          }
        `
      case 'imageRightOfText':
      default:
        return css`
          img {
            height: 100%;
            top: 0;
            position: absolute;
            left: 100px;
            width: 100%;
            display: block;
            object-fit: contain;
          }
          @media (min-width: 576px) {
            img {
              top: 50%;
              left: 50%;
              width: 60%;
              transform: translateY(-50%) translateX(-2rem);
            }
          }
        `
    }
  }}
}
`

export const SlideImageBackground = styled.div<{
  $heroImageBackgroundFit?: Maybe<Scalars['String']>
}>`
  ${({ $heroImageBackgroundFit }) => {
    switch ($heroImageBackgroundFit) {
      case 'cover':
        return css`
          img {
            height: 100%;
            position: absolute;
            width: 100%;
            display: block;
            object-fit: cover;
          }
        `
      case 'containLeft':
        return css`
          img {
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            max-width: unset;
            display: block;
            object-fit: contain;
          }
        `
      case 'containRight':
        return css`
          img {
            height: 100%;
            top: 0;
            right: 0;
            position: absolute;
            max-width: unset;
            display: block;
            object-fit: contain;
          }
        `
      case 'contain':
      default:
        return css`
          img {
            height: 100%;
            top: 0;
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            display: block;
            object-fit: contain;
          }
        `
    }
  }}
`

export const Cta = styled.div`
  a {
    text-decoration: none;
  }
  .overlay-link {
    position: absolute;
    width: 80%;
    height: 80%;
    top: 10%;
    left: 10%;
  }
  @media (min-width: 576px) {
    .overlay-link {
      display: none;
    }
  }
`

export const CtaButton = styled(PrimaryButton)<{
  $ctaTextColor: SanityCarouselHalfHero['ctaTextColor']
  $ctaBackgroundColor: SanityCarouselHalfHero['ctaBackgroundColor']
}>`
  display: none;
  box-sizing: border-box;
  color: ${({ $ctaTextColor, theme }) =>
    $ctaTextColor?.color || theme.colors.component.PrimaryButton.default.text};
  background-color: ${({ $ctaBackgroundColor, theme }) =>
    $ctaBackgroundColor?.color || theme.colors.brand.orange};
  &:hover {
    background-color: ${({ $ctaBackgroundColor, theme }) =>
      $ctaBackgroundColor?.color
        ? $ctaBackgroundColor.color + 'bb'
        : theme.colors.brand.orange + 'bb'};
  }

  && {
    padding-left: 32px;
    padding-right: 32px;
  }
  @media (min-width: 576px) {
    display: inline-block;
  }
  @media (min-width: 768px) {
    font-size: 0.9375rem;
  }
  @media (min-width: 992px) {
    font-size: 1rem;
  }
`
