import { GatsbyImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'

import { SanityLeadershipGroup } from '../../../graphql/gatsby'

export const TeamMember = styled.section`
  padding: var(--gap);
  background-color: ${({ theme }) => theme.colors.neutralGray.neutral200};
  box-shadow: rgb(11 11 11 / 21%) 1px 3px 7px 0.5px;
  position: relative;
  flex: 1;
`

export const TeamMemberGroup = styled.div<Pick<SanityLeadershipGroup, 'layout'>>`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  gap: var(--gap);
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  color: ${({ theme }) => theme.colors.brand.gray};
  p {
    margin-top: 16px;
    font-family: ${({ theme }) => theme.fontFamilies.gotham};
    font-size: 12px;
    letter-spacing: 1.1px;
    line-height: 1.7;
  }

  @media (min-width: 768px) {
    ${({ layout }) => {
      switch (layout) {
        case '2-col-1-row':
          return css`
            grid-template-columns: 1fr 1fr;
          `
        case '2-col-rowspan-left':
          return css`
            grid-template-columns: 1fr 1fr;

            ${TeamMember}:first-of-type {
              grid-column: 1;
              grid-row: 1 / 3;
            }
          `
        case '4-col-1-row':
          return css`
            grid-template-columns: repeat(2, 1fr);
          `
        default:
          return ''
      }
    }};
  }

  @media (min-width: 1280px) {
    ${({ layout }) => {
      switch (layout) {
        case '4-col-1-row':
          return css`
            grid-template-columns: repeat(4, 1fr);
          `
        default:
          return ''
      }
    }};
    p {
      font-size: 14px;
    }
  }
`

export const Container = styled.div`
  --gap: 24px;
  display: grid;
  gap: var(--gap);
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  padding: var(--gap);
  background-color: ${({ theme }) => theme.colors.grayscale.white};
`

export const LeadershipMemberImage = styled(GatsbyImage)``

export const ImageAndHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  & ~ p {
    margin-top: 16px;
  }
  ${LeadershipMemberImage} {
    margin-right: 16px;
  }
`

export const Header = styled.h2`
  margin-top: 16px;
  margin-bottom: 0;
  flex: 1;
  min-width: 45%;
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: bold;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.component.textDark};
`

export const Title = styled.span`
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 1.1px;
  color: ${({ theme }) => theme.colors.brand.gray};
  display: block;
`
