import React from 'react'
import styled from 'styled-components'

import { SanityBasicTextSection } from '../../graphql/gatsby'
import { useSanityRoleBasedContent } from '../../lib/sanity/hooks/useSanityRoleBasedContent'
import BodyPortableText from './BodyPortableText'
import { pageSectionStylesFor, textStylesFor } from './styles'

export interface BasicTextSectionProps extends SanityBasicTextSection {
  className?: string
}

const BasicTextSection: React.FC<BasicTextSectionProps> = ({
  className,
  roleBasedContent,
  body,
}) => {
  const { shouldDisplay } = useSanityRoleBasedContent({ roleBasedContent })

  if (!body || !shouldDisplay) {
    return null
  }
  return <BodyPortableText className={className} blocks={body} />
}

const StyledBasicPortableText = styled(BasicTextSection)<BasicTextSectionProps>`
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  width: 100%;
  ${({ pageSectionStyles }) => pageSectionStylesFor(pageSectionStyles)}
  ${({ textStyles }) => textStylesFor(textStyles)}
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  em {
    font-style: italic;
  }

  @media (min-width: 768px) {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  @media (min-width: 992px) {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
`

export default StyledBasicPortableText
