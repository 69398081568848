import BlockContent from '@sanity/block-content-to-react'
import React from 'react'

import {
  SanityLeadershipGrid,
  SanityLeadershipGroup,
  SanityTeamMemberLeadership,
} from '../../../graphql/gatsby'
import { getGatsbyImageDataForAsset } from '../../../utils/sanity/image'
import * as Styled from './styled'

export type LeadershipMemberProps = SanityTeamMemberLeadership

const LeadershipMember: React.FC<LeadershipMemberProps> = ({ name, title, photo, biography }) => {
  const image = getGatsbyImageDataForAsset(photo, {
    width: 212,
    height: 246,
    placeholder: 'blurred',
  })
  return (
    <Styled.TeamMember>
      <Styled.ImageAndHeader>
        {image && <Styled.LeadershipMemberImage image={image} alt="" />}
        <Styled.Header>
          {name ? `${name}` : ''}
          {title && <Styled.Title>{title}</Styled.Title>}
        </Styled.Header>
      </Styled.ImageAndHeader>
      {biography && <BlockContent blocks={biography} />}
    </Styled.TeamMember>
  )
}

export type LeadershipGroupProps = SanityLeadershipGroup

const LeadershipGroup: React.VFC<LeadershipGroupProps> = ({ layout, members }) => {
  if (!layout || !members) return null
  return (
    <Styled.TeamMemberGroup layout={layout}>
      {members.map((member, index) => {
        return member && <LeadershipMember {...member} key={index} />
      })}
    </Styled.TeamMemberGroup>
  )
}

export type LeadershipGridProps = SanityLeadershipGrid

export const LeadershipGrid: React.VFC<LeadershipGridProps> = ({ groups }) => {
  if (!groups) {
    return null
  }
  return (
    <Styled.Container>
      {groups.map((group, index) => {
        return group && <LeadershipGroup {...group} key={index} />
      })}
    </Styled.Container>
  )
}
