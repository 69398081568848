import React from 'react'
import rehype2react from 'rehype-react'
import markdown from 'remark-parse'
import remark2rehype from 'remark-rehype'
import unified from 'unified'

import { SanityNotification } from '../../../graphql/gatsby'
import * as Styled from './styled'

const markdownProcessor = unified()
  .use(markdown)
  .use(remark2rehype)
  .use(rehype2react, { createElement: React.createElement })

export type NotificationProps = SanityNotification

export const Notification: React.FC<NotificationProps> = React.memo(({ notificationMessage }) => {
  if (!notificationMessage) return null
  const { title, content } = notificationMessage

  return (
    <Styled.Notification>
      <Styled.MaxWidthContainer>
        <Styled.NotificationPanel>
          <Styled.Title>{title || 'Important Notice'}</Styled.Title>
          <Styled.Content>
            {content && (markdownProcessor.processSync(content).result as JSX.Element)}
          </Styled.Content>
        </Styled.NotificationPanel>
      </Styled.MaxWidthContainer>
    </Styled.Notification>
  )
})

Notification.displayName = 'Notification'
