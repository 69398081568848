import styled, { css } from 'styled-components'

import { SanityIconAndTextGrid } from '../../../graphql/gatsby'
import { headingFontSize } from '../../../theme/helpers'
import BodyPortableText from '../BodyPortableText'
import { pageSectionStylesFor } from '../styles'

/**
 * Calculate grid offsets for IconAndText components
 */
const gridOffsetVariables = (index: number) => {
  return css`
    --col-offset: 0;
    --row-offset: ${index * 4};
    @media (min-width: 576px) {
      --col-offset: ${index % 2};
      --row-offset: ${Math.floor(index / 2) * 4};
    }
    @media (min-width: 768px) {
      --col-offset: ${index % 4};
      --row-offset: ${Math.floor(index / 4) * 4};
    }
  `
}

export const Container = styled.div<Pick<SanityIconAndTextGrid, 'pageSectionStyles'>>`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  background-color: #eff7ff;
  padding: 3rem 2.25rem 3rem 2.25rem;
  ${({ pageSectionStyles }) => pageSectionStylesFor(pageSectionStyles)};
  @media (min-width: 768px) {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  @media (min-width: 992px) {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-size: ${({ theme }) => headingFontSize({ size: 3, theme })};
  margin-top: 1rem;
  margin-bottom: 3rem;
  text-align: center;
`

export const IconTitle = styled.p<{ $index: number }>`
  color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: bold;
  font-size: ${({ theme }) => headingFontSize({ size: 2, theme })};
  text-align: center;
  padding-top: 1.875rem;
  margin-bottom: 0.75rem;
  max-width: var(--icon-size);
  ${({ $index }) => gridOffsetVariables($index)};
  grid-row: calc(var(--row-offset) + 2);
  grid-column: calc(var(--col-offset) + 1);
`

export const Icon = styled.img`
  display: block;
  max-width: 100%;
`

export const Text = styled(BodyPortableText)<{ $index: number }>`
  ${({ $index }) => gridOffsetVariables($index)};
  grid-row: calc(var(--row-offset) + 3);
  grid-column: calc(var(--col-offset) + 1);
  p,
  ul {
    line-height: 1.9;
  }
`

export const IconWrapper = styled.div<{ $index: number }>`
  width: var(--icon-size);
  height: var(--icon-size);
  border-radius: 50%;
  padding: 35px;
  background-color: ${({ theme }) => theme.colors.brand.orange};
  overflow: hidden;
  ${({ $index }) => gridOffsetVariables($index)};
  grid-row: calc(var(--row-offset) + 1);
  grid-column: calc(var(--col-offset) + 1);
  box-sizing: border-box;
`

export const IconGroup = styled.div`
  --icon-size: 140px;
  display: grid;
  column-gap: 32px;
  // set minimum row size to 32px for gap between sets
  // - child elements skip every 4th row via grid-row
  grid-auto-rows: minmax(32px, max-content);
  // fill width of container
  justify-content: stretch;
  align-content: start;
  // center items in each column
  justify-items: center;
`
