import React from 'react'

import { SanityBlockMenuAdditionalShortcuts } from '../../../../graphql/gatsby'
import { BlockMenuLink } from '../BlockMenuLink'
import * as Styled from './styled'

export type BlockMenuAdditionalShortcutsProps = SanityBlockMenuAdditionalShortcuts

export const BlockMenuAdditionalShortcuts: React.FC<BlockMenuAdditionalShortcutsProps> = ({
  ariaLabel,
  layout,
  links,
}) => {
  return (
    <Styled.BlockMenuAdditionalShortcutsContainer
      className={layout || ''}
      aria-label={ariaLabel || ''}
    >
      <Styled.BlockMenuAdditionalShortcutsList>
        {links &&
          links.map((blockLink, index) => (
            <BlockMenuLink key={index} layout={layout || ''} {...blockLink} />
          ))}
      </Styled.BlockMenuAdditionalShortcutsList>
    </Styled.BlockMenuAdditionalShortcutsContainer>
  )
}
