import { graphql } from 'gatsby'
import React, { useContext } from 'react'

import { SanityPageContext } from '../lib/sanity/context'
import { StyledFunctionComponent } from './types'

const srcSetArrayToString = (srcSet: (string | null | undefined)[]): string | undefined => {
  return srcSet.filter((src) => !!src).join(', ') || undefined
}

const ComingSoonImage: StyledFunctionComponent = ({ className }) => {
  const {
    comingSoon1x,
    comingSoon2x,
    comingSoon3x,
    comingSoon1xWebp,
    comingSoon2xWebp,
    comingSoon3xWebp,
  } = useContext(SanityPageContext)

  const comingSoon1xWebpSrc = comingSoon1xWebp?.childImageSharp?.resize?.src
  const comingSoon2xWebpSrc = comingSoon2xWebp?.childImageSharp?.resize?.src
  const comingSoon3xWebpSrc = comingSoon3xWebp?.childImageSharp?.resize?.src

  const comingSoon1xSrc = comingSoon1x?.childImageSharp?.resize?.src
  const comingSoon2xSrc = comingSoon2x?.childImageSharp?.resize?.src
  const comingSoon3xSrc = comingSoon3x?.childImageSharp?.resize?.src

  return (
    <picture>
      <source
        type="image/webp"
        srcSet={srcSetArrayToString([
          comingSoon1xWebpSrc,
          comingSoon2xWebpSrc ? `${comingSoon2xWebpSrc} 2x` : undefined,
          comingSoon3xWebpSrc ? `${comingSoon3xWebpSrc} 3x` : undefined,
        ])}
      />
      <img
        src={comingSoon1xSrc ?? undefined}
        alt="Coming soon"
        className={className}
        srcSet={srcSetArrayToString([
          comingSoon1xSrc,
          comingSoon2xSrc ? `${comingSoon2xSrc} 2x` : undefined,
          comingSoon3xSrc ? `${comingSoon3xSrc} 3x` : undefined,
        ])}
      />
    </picture>
  )
}

export const comingSoonImageFragment = graphql`
  fragment comingSoonImage on Query {
    comingSoon1x: file(relativePath: { eq: "block-menu/coming-soon-flag.png" }) {
      childImageSharp {
        resize(width: 146, quality: 90) {
          src
        }
      }
    }
    comingSoon1xWebp: file(relativePath: { eq: "block-menu/coming-soon-flag.png" }) {
      childImageSharp {
        resize(width: 146, quality: 90, toFormat: WEBP) {
          src
        }
      }
    }
    comingSoon2x: file(relativePath: { eq: "block-menu/coming-soon-flag.png" }) {
      childImageSharp {
        resize(width: 292, quality: 90) {
          src
        }
      }
    }
    comingSoon2xWebp: file(relativePath: { eq: "block-menu/coming-soon-flag.png" }) {
      childImageSharp {
        resize(width: 292, quality: 90, toFormat: WEBP) {
          src
        }
      }
    }
    comingSoon3x: file(relativePath: { eq: "block-menu/coming-soon-flag.png" }) {
      childImageSharp {
        resize(width: 438, quality: 90) {
          src
        }
      }
    }
    comingSoon3xWebp: file(relativePath: { eq: "block-menu/coming-soon-flag.png" }) {
      childImageSharp {
        resize(width: 438, quality: 90, toFormat: WEBP) {
          src
        }
      }
    }
  }
`

export default ComingSoonImage
