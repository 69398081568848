import { pipe } from 'ramda'

import { SanityProductList } from '../../groq/sanity-dispensaries/types'

export const mainProductList = (
  productLists: SanityProductList[] | null | undefined,
): SanityProductList | null => productLists?.[0] ?? null

export const productListWithDefaults = (
  productList: SanityProductList | null,
): { title: string; skus: string[] } => ({
  title: productList?.title ?? 'Featured Products',
  skus: productList?.skus ?? [],
})

export const mainProductListWithDefaults = pipe(mainProductList, productListWithDefaults)
