import React from 'react'
import styled from 'styled-components'

import { SanityLogoDividerSection } from '../../graphql/gatsby'
import { StyledFunctionComponent } from '../types'
import { pageSectionStylesFor } from './styles'

import darkLogo from '../../img/logo-dark.svg'

const StyledLogoImage = styled.img`
  display: block;
  margin: auto;
  max-width: 300px;
`

const LogoDividerSection: StyledFunctionComponent<SanityLogoDividerSection> = ({
  className,
  alt,
}) => {
  return (
    <div className={className}>
      <StyledLogoImage src={darkLogo} alt={alt || undefined} />
    </div>
  )
}

const StyledLogoDividerSection = styled(LogoDividerSection)`
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  box-sizing: border-box;
  width: 100%;
  ${({ pageSectionStyles }) => pageSectionStylesFor(pageSectionStyles)}
  padding-left: 2.25rem;
  padding-right: 2.25rem;

  @media (min-width: 768px) {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  @media (min-width: 992px) {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
`

export default StyledLogoDividerSection
