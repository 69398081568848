import { bind, SUSPENSE } from '@react-rxjs/core'
import { createSignal } from '@react-rxjs/utils'
import { map, of, switchMap } from 'rxjs'

import { mapRight, mapRightSuspended, switchMapRight } from '../../utils/rx/operators'
import { searchExactQuery } from '../search/exact-query'
import { dispensariesSiteSettingsCdn$, sanityDispensaryCdn$ } from './query'
import { latestDispensarySlug$ } from './state'
import { mainProductListWithDefaults } from './utils'

const defaultProductList$ = dispensariesSiteSettingsCdn$.pipe(
  mapRight((siteSettingsResult) => ({
    _tag: 'Right' as const,
    data: mainProductListWithDefaults(siteSettingsResult.data?.defaultDispensaryProductLists),
  })),
)

const dispensaryProductList$ = sanityDispensaryCdn$.pipe(
  mapRight((dispensaryResult) => ({
    _tag: 'Right' as const,
    data: mainProductListWithDefaults(dispensaryResult.data?.productLists),
  })),
)

const dispensaryProductListWithDefault$ = dispensaryProductList$.pipe(
  switchMap((dispensarySkusResult) =>
    dispensarySkusResult._tag === 'Left' || dispensarySkusResult.data.skus.length > 0
      ? of(dispensarySkusResult)
      : defaultProductList$,
  ),
)

const [searchFeaturedProducts$, searchFeaturedProducts] = createSignal<{
  forceDefault?: boolean | null
}>()

export { searchFeaturedProducts }

const featuredProducts$ = searchFeaturedProducts$.pipe(
  switchMap(({ forceDefault }) =>
    forceDefault
      ? defaultProductList$
      : latestDispensarySlug$.pipe(
          switchMap((dispensarySlug) =>
            !dispensarySlug ? defaultProductList$ : dispensaryProductListWithDefault$,
          ),
        ),
  ),
  switchMapRight(({ data: { title, skus } }) =>
    searchExactQuery({ skus }).pipe(
      mapRightSuspended((value) => ({
        _tag: 'Right' as const,
        data: { title, products: value.data },
      })),
      map((value) =>
        value === SUSPENSE
          ? {
              _tag: 'Right' as const,
              data: { title: '', products: [] },
            }
          : value,
      ),
    ),
  ),
)

export const [useFeaturedProducts, latestFeaturedProducts$] = bind(featuredProducts$)
