import { gothamFamilies, robotoCondensedFamilies } from '@designsforhealth/dfh-react-components'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { libraryPropTypes } from '../../../utils/libraryTools'
import LibraryIntroMessage from './LibraryIntroMessage'

const LibraryBreadcrumbs = ({ className, meta, libraryType }) => {
  const { count } = meta
  const totalDocumentCount = count.all

  return (
    <div className={`${className} faceted-top-bar`}>
      <div className="breadcrumb-row">
        <div className={classNames('breadcrumb-col', 'display-none', 'display-md-block')}>
          <nav className="breadcrumb-nav" aria-label="Breadcrumb Navigation">
            <div className="breadcrumb-container">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <span>Research &amp; Education</span>
                </li>
                <li className="breadcrumb-divider">
                  <FontAwesomeIcon icon={faAngleRight} />
                </li>
                <li className="breadcrumb-item-active">Library</li>
              </ul>
            </div>
          </nav>
        </div>
        <div className="total-documents-col">
          <div className="faceted-info-container">
            <span className="faceted-num-results">{totalDocumentCount} Documents</span>
          </div>
        </div>
        <div className={classNames('breadcrumb-col-intro-message', 'display-lg-none')}>
          <LibraryIntroMessage libraryType={libraryType} />
        </div>
      </div>
    </div>
  )
}

LibraryBreadcrumbs.defaultProps = {
  className: '',
}

LibraryBreadcrumbs.propTypes = {
  className: PropTypes.string,
  meta: libraryPropTypes.meta.isRequired,
  libraryType: PropTypes.oneOf(['public', 'professional']).isRequired,
}

const StyledLibraryBreadcrumbs = styled(LibraryBreadcrumbs)(({ theme }) => ({
  '&.faceted-top-bar': {
    background: theme.colors.grayscale.white,
    color: theme.colors.brand.gray,
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '16px 16px',
    boxSizing: 'border-box',
    '.breadcrumb-row': {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: '-8px',
      marginRight: '-8px',
      marginTop: '-8px',
      marginBottom: '-8px',
    },
    '.breadcrumb-row>.breadcrumb-col, .breadcrumb-row>.total-documents-col': {
      position: 'relative',
      width: '100%',
      minHeight: '1px',
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingTop: '8px',
      paddingBottom: '8px',
      boxSizing: 'border-box',
    },
    '.faceted-info-container': {
      fontFamily: gothamFamilies,
      textAlign: 'right',
    },
    '.faceted-num-results': {
      fontSize: '1rem',
    },
  },
  '.breadcrumb-row': {
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: '15px',
    marginLeft: '15px',
  },
  '.breadcrumb-col, .total-documents-col': {
    flexBasis: '0',
    flexGrow: '1',
    minWidth: '0',
    maxWidth: '100%',
    position: 'relative',
    width: '100%',
    paddingRight: '15px',
    paddingLeft: '15px',
    boxSizing: 'border-box',
  },
  '.breadcrumb-col-intro-message': {
    position: 'relative',
    width: '100%',
    minHeight: '1px',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: '8px',
    paddingBottom: '8px',
    flex: '0 0 100%',
    maxWidth: '100%',
    textAlign: 'left',
    boxSizing: 'border-box',
    p: {
      lineHeight: '1.5',
    },
  },
  '.breadcrumb': {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0',
    marginBottom: '0',
    listStyle: 'none',
    fontFamily: robotoCondensedFamilies,
    fontWeight: '500',
    letterSpacing: '.1em',
    '.breadcrumb-divider': {
      padding: '0 16px',
    },
  },
  '@media(min-width: 768px)': {
    '&.faceted-top-bar': {
      '.breadcrumb-row': {
        marginLeft: '-12px',
        marginRight: '-12px',
      },
      '.breadcrumb-row>.breadcrumb-col, .breadcrumb-row>.total-documents-col': {
        paddingLeft: '12px',
        paddingRight: '12px',
      },
    },
    '.total-documents-col': {
      flex: '0 0 25%',
      maxWidth: '25%',
    },
    '.breadcrumb-col-intro-message': {
      paddingLeft: '12px',
      paddingRight: '12px',
    },
  },
}))

export default StyledLibraryBreadcrumbs
