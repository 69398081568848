import React from 'react'
import styled from 'styled-components'

import { SanitySpacerSection } from '../../graphql/gatsby'
import { backgroundStylesFor, spacerSectionStylesFor } from './styles'

export interface SpacerSectionProps extends SanitySpacerSection {
  className?: string
}

const SpacerSection: React.FC<SpacerSectionProps> = ({ className }) => {
  return <div className={className} />
}

const StyledSpacerSection = styled(SpacerSection)<SpacerSectionProps>`
  ${({ backgroundStyles }) => backgroundStylesFor(backgroundStyles)};
  ${({ size }) => spacerSectionStylesFor({ size })}
  width: 100%;
`

export default StyledSpacerSection
