import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

export const LibraryDimmer = ({ children, className = 'dimmer', element = 'div' }) => {
  const [container] = useState(() => {
    const el = document.createElement(element)
    el.classList.add(className)
    return el
  })

  useEffect(() => {
    const [facetedList] = document.getElementsByClassName('faceted-list')
    facetedList.appendChild(container)
    return () => {
      facetedList.removeChild(container)
    }
  }, [container])

  return createPortal(children, container)
}
