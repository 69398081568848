import type { BlockContentProps } from '@sanity/block-content-to-react'

import type { SetCurrentlyOpenTermsModal } from '../types'
import { BlockRenderer } from './block'
import { ListItemRenderer, ListRenderer } from './list'
import { AnchorIdMarkRenderer, getComponentBlockMarkRenderer, LinkMarkRenderer } from './marks'

export const getSerializers = ({
  setCurrentlyOpenTermsModal,
}: {
  setCurrentlyOpenTermsModal: SetCurrentlyOpenTermsModal
}): NonNullable<BlockContentProps['serializers']> => ({
  types: {
    block: BlockRenderer,
  },
  marks: {
    link: LinkMarkRenderer,
    anchorId: AnchorIdMarkRenderer,
    componentBlock: getComponentBlockMarkRenderer({ setCurrentlyOpenTermsModal }),
  },
  list: ListRenderer,
  listItem: ListItemRenderer,
})
