import BlockContent from '@sanity/block-content-to-react'
import styled from 'styled-components'

import { SanityDispensaryBio } from '../../../graphql/gatsby'
import { headingFontSize } from '../../../theme/helpers'
import { pageSectionStylesFor } from '../styles'

export const Container = styled.div<Pick<SanityDispensaryBio, 'pageSectionStyles'>>`
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  width: 100%;
  overflow: hidden; // https://macromedia.dreamweaver.narkive.com/qv8QEcss/div-not-expanding-height-to-fit-image-height-with-css
  ${({ pageSectionStyles }) => pageSectionStylesFor(pageSectionStyles)}
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  @media (min-width: 768px) {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  @media (min-width: 992px) {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
  padding-top: 1.87rem;
`

export const Image = styled.img`
  float: left;
  padding-bottom: 20px;
  width: 100%;
  @media (min-width: 600px) {
    width: 25%;
    padding-right: 30px;
  }
  @media (min-width: 992px) {
    width: 33%;
  }
`

export const BioPortableText = styled(BlockContent)``

export const Heading1 = styled.h1`
  color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
  line-height: 1;
  font-size: ${({ theme }) => headingFontSize({ size: 4, theme })};
  margin-block-end: 0.5em;
`

export const Heading2 = styled.h2`
  color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
  line-height: 1.5;
  font-size: ${({ theme }) => headingFontSize({ size: 3, theme })};
  margin-block-end: 0.5em;
`

export const Paragraph = styled.p`
  line-height: 1.5;
  font-size: 14px;
  margin-block-end: 0.83em;
`

export const Blockquote = styled.blockquote`
  overflow: hidden;
  font-size: 14px;
  border-left: 4px solid ${({ theme }) => theme.colors.coolGray.cool450};
  padding-left: 1rem;
  margin: 0;
  margin-block-end: 1em;
`

export const NumberedList = styled.ol`
  line-height: 1.5;
  font-size: 14px;
  margin-block-end: 1em;
  padding-inline-start: 1rem;
`

export const BulletList = styled.ul`
  line-height: 1.5;
  font-size: 14px;
  margin-block-end: 1em;
  padding-inline-start: 1rem;
`

export const ListItem = styled.li`
  overflow: hidden;
  list-style: none;

  ${NumberedList} & {
    margin-left: -0.5rem;

    &::before {
      content: counter(list-item) '. ';
      display: inline-block;
      width: 1rem;
      text-align: center;
      margin-right: 0.5rem;
    }
  }

  ${BulletList} & {
    &::before {
      content: '• ';
      display: inline-block;
      width: 1rem;
    }
  }
`

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding-bottom: 20px;
`
