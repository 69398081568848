import React from 'react'

import { TermsLinkComponent } from '../types'
import { TermsModalTriggerLink } from './TermsModalTriggerLink'

export const TermsOfUseLink: TermsLinkComponent = ({
  className,
  setCurrentlyOpenTermsModal,
  text,
}) => (
  <TermsModalTriggerLink
    className={className}
    modalType="termsOfUse"
    setCurrentlyOpenTermsModal={setCurrentlyOpenTermsModal}
  >
    {text}
  </TermsModalTriggerLink>
)

TermsOfUseLink.defaultProps = {
  className: '',
  text: 'Terms of Use',
}
