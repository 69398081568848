import { Typography, TypographyProps } from '@material-ui/core'
import BlockContent from '@sanity/block-content-to-react'
import { format, parseISO } from 'date-fns'
import React, { FC, useMemo } from 'react'
import styled from 'styled-components'

import { getSerializers } from './sanity-renderers'
import { SetCurrentlyOpenTermsModal, Terms, TermsModalType } from './types'

const StyledLastModified = styled(Typography)<TypographyProps<'div', { component: 'div' }>>`
  margin-bottom: 20px;
`

const StyledBlockContent = styled(BlockContent)`
  a[href] {
    color: ${({ theme }) => theme.colors.component.link};
  }
  blockquote {
    margin-left: 1rem;
    @media (min-width: 525px) {
      margin-left: 2rem;
    }
  }
  em {
    font-style: italic;
  }
`

export interface TermsModalContentProps {
  terms: Terms
  setCurrentlyOpenTermsModal: SetCurrentlyOpenTermsModal
  currentlyOpenTermsModal?: TermsModalType | null
  open: boolean
}

export const TermsModalContent: FC<TermsModalContentProps> = ({
  terms,
  open,
  setCurrentlyOpenTermsModal,
}) => {
  const serializers = useMemo(
    () => getSerializers({ setCurrentlyOpenTermsModal }),
    [setCurrentlyOpenTermsModal],
  )
  if (!open) {
    return null
  }
  return (
    <>
      {terms && terms._rawContent && Array.isArray(terms._rawContent) && (
        <>
          <StyledLastModified component="div" variant="caption" gutterBottom>
            <strong>Last Modified:</strong> {format(parseISO(terms.lastModified), 'PP')}
          </StyledLastModified>
          <StyledBlockContent blocks={terms._rawContent} serializers={serializers} />
        </>
      )}
    </>
  )
}
