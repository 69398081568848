// import Swiper styles
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'

import React, { useEffect, useMemo, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import SwiperClass, { Autoplay, EffectFade, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import useIsMounted from '../../../hooks/useIsMounted'
import useIsSsr from '../../../hooks/useIsSsr'
import { pillarBox1920Styles } from '../../../lib/styles/pillar-box'
import { logAndCaptureMessage } from '../../../utils/errorTools'
import { CarouselSlideHalfHero } from './CarouselSlideHalfHero'
import { CarouselSlideWithComponent } from './types'

const StyledSwiper = styled(Swiper)`
  && {
    width: 100%;
    height: var(--carousel-height);
    .swiper-pagination {
      pointer-events: none;
    }
    .swiper-pagination-bullets {
      bottom: 28px;
      .swiper-pagination-bullet {
        width: 15px;
        height: 15px;
        background: ${({ theme }) => theme.colors.brand.gray};
      }
      .swiper-pagination-bullet-active {
        background: ${({ theme }) => theme.colors.grayscale.white};
      }
    }
    .swiper-slide {
      overflow: hidden;
      background-color: var(--slide-bg-color, ${({ theme }) => theme.colors.brandBlue.dark200});
    }
    @media (min-width: 992px) {
      .swiper-pagination-clickable {
        .swiper-pagination-bullet {
          pointer-events: auto;
        }
      }
    }
  }
`

/**
 * Slide container for nesting under SwiperSlide
 */
export const CarouselSlide = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  height: 100%;
  color: ${({ theme }) => theme.colors.grayscale.white};
  ${pillarBox1920Styles};

  ul {
    list-style: disc;
    line-height: 1.5;
    padding-left: 16.5px;
  }
`

export interface CarouselDeckProps {
  className?: string
  slides: CarouselSlideWithComponent[]
}

const CarouselDeck: React.VFC<CarouselDeckProps> = ({ className, slides }) => {
  // render first slide only during SSR
  const [carouselSlides, setCarouselSlides] = useState(slides.slice(0, 1))
  const theme = useTheme()

  const slideElements = useMemo(
    () =>
      carouselSlides.reduce((acc: React.ReactElement[], slide, index) => {
        const {
          carousel: [carouselComponent],
        } = slide
        switch (carouselComponent._type) {
          case 'carouselHalfHero':
            acc.push(
              <SwiperSlide
                tag="article"
                key={carouselComponent._key ?? index}
                style={{
                  ['--slide-bg-color' as keyof React.CSSProperties]:
                    carouselComponent.slideBackgroundColor?.color || theme.colors.brandBlue.dark200,
                }}
              >
                <CarouselSlide>
                  <CarouselSlideHalfHero {...carouselComponent} />
                </CarouselSlide>
              </SwiperSlide>,
            )
            break
          default:
            logAndCaptureMessage(
              `Component not found for carousel slide "${carouselComponent.__typename}"`,
            )
            break
        }
        return acc
      }, []),
    [carouselSlides, theme.colors.brandBlue.dark200],
  )

  const isSSR = useIsSsr()
  const isMounted = useIsMounted()
  const [swiperInstance, setSwiperInstance] = useState<SwiperClass | undefined>(undefined)
  const [swiperEnabled, setSwiperEnabled] = useState(false)

  // render entire set of slides client-side
  useEffect(() => {
    if (isSSR) {
      return
    }
    setTimeout(() => {
      if (isMounted.current) {
        setCarouselSlides(slides)
      }
    }, 5000)
  }, [isMounted, isSSR, slides])

  // enable Swiper after carousel slides loaded (prop is not watched)
  useEffect(() => {
    if (swiperEnabled || slideElements.length <= 1 || !swiperInstance) {
      return
    }
    swiperInstance.params.autoplay = { delay: 5000 }
    swiperInstance.enable()
    swiperInstance.autoplay.start()
    setSwiperEnabled(true)
  }, [slideElements.length, swiperEnabled, swiperInstance])

  return (
    <StyledSwiper
      className={className}
      enabled={false}
      loop
      modules={[Pagination, Autoplay, EffectFade]}
      pagination={{ clickable: true }}
      spaceBetween={0}
      speed={450}
      onSwiper={setSwiperInstance}
      effect="fade"
      fadeEffect={{ crossFade: true }}
    >
      {slideElements}
    </StyledSwiper>
  )
}

export default CarouselDeck
