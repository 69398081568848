import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import { SanityImageAndTextSection } from '../../../graphql/gatsby'
import { headingFontSize } from '../../../theme/helpers'
import { pageSectionStylesFor } from '../styles'

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-size: ${({ theme }) => headingFontSize({ size: 4, theme })};
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  line-height: 1.5;
`

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-size: ${({ theme }) => headingFontSize({ size: 2, theme })};
  font-weight: 400;
  line-height: 1.5;
  margin-top: -0.75em;
  margin-bottom: 0;
`

export const Container = styled.div<
  Pick<SanityImageAndTextSection, 'pageSectionStyles' | 'layout'>
>`
  display: grid;
  grid-template-areas:
    'image'
    'text';
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  gap: 32px;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  ${({ pageSectionStyles }) => pageSectionStylesFor(pageSectionStyles)};
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: ${({ layout }) =>
      layout === 'image-right' ? '"text image"' : '"image text"'};
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  @media (min-width: 992px) {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
`

export const Text = styled.div<Pick<SanityImageAndTextSection, 'textAlign'>>`
  grid-area: text;
  align-self: ${({ textAlign }) =>
    textAlign === 'top'
      ? 'start'
      : textAlign === 'center'
        ? 'center'
        : textAlign === 'bottom'
          ? 'end'
          : 'auto'};
`

export const Image = styled(GatsbyImage)`
  grid-area: image;
  object-fit: contain;
  max-width: 550px;
`

export const Header = styled.header`
  margin-bottom: 1rem;
  @media (min-width: 768px) {
    margin-bottom: 2rem;
  }
`
