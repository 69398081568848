import styled from 'styled-components'

import { pillarBox1920Styles } from '../../../../lib/styles/pillar-box'
import { backgroundStyles, BackgroundStylesMixin } from '../../styles'

export const Header = styled.h2`
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: bold;
  font-size: 1.625rem;
  color: ${({ theme }) => theme.colors.component.textDark};
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  padding-top: 2rem;
`

export const BlockMenuCta = styled.div<BackgroundStylesMixin>(backgroundStyles)

export const BlockMenuCtaContainer = styled.nav`
  position: relative;
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  width: 100%;
  padding: 2px 0;
  box-sizing: border-box;
  ${pillarBox1920Styles};
  @media (min-width: 432px) {
    padding: 14px 0;
  }
  @media (min-width: 992px) {
    padding: 18px 16px;
  }
`

export const BlockMenuCtaLinksList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  justify-content: center;
  list-style: none;
  @media (min-width: 432px) {
    margin-top: -5px;
    margin-bottom: -5px;
  }
  @media (min-width: 576px) {
    margin: -7px -8px;
  }
  @media (min-width: 992px) {
    margin-left: -8px;
    margin-right: -8px;
  }
`
