import React, { useMemo } from 'react'
import rehype2react from 'rehype-react'
import markdown from 'remark-parse'
import remark2rehype from 'remark-rehype'
import unified from 'unified'

import { SanityCarouselHalfHero } from '../../../../graphql/gatsby'
import { isNotNull } from '../../../../utils/collectionTools'
import { imageUrlFor } from '../../../../utils/image-url'
import SanityLink from '../../../global/SanityLink'
import * as Styled from './styled'

const markdownProcessor = unified()
  .use(markdown)
  .use(remark2rehype)
  .use(rehype2react, { createElement: React.createElement })

export type CarouselSlideHalfHeroProps = SanityCarouselHalfHero

const srcSetArrayToString = (srcSet: (string | null | undefined)[]) =>
  srcSet.filter(isNotNull).join(', ') || undefined

const appendUnlessEmpty = (suffix: string) => (value: string | null | undefined) =>
  value ? `${value}${suffix}` : value

const append2x = appendUnlessEmpty(' 2x')
const append3x = appendUnlessEmpty(' 3x')

export const CarouselSlideHalfHero: React.FC<CarouselSlideHalfHeroProps> = ({
  heroImage,
  heroImageMobile,
  heroBackgroundImageFit,
  heroTextStyles,
  titleDesktop,
  titleMobile,
  descriptionDesktop,
  descriptionMobile,
  heroImageTextLayout,
  cta,
  ctaBackgroundColor,
  ctaTextColor,
}) => {
  const descriptionDesktopElement = useMemo(
    () =>
      descriptionDesktop
        ? (markdownProcessor.processSync(descriptionDesktop).result as JSX.Element)
        : undefined,
    [descriptionDesktop],
  )

  const descriptionMobileElement = useMemo(
    () =>
      descriptionMobile
        ? (markdownProcessor.processSync(descriptionMobile).result as JSX.Element)
        : undefined,
    [descriptionMobile],
  )

  const heroImageElement = useMemo(() => {
    if (!heroImage) {
      return undefined
    }
    const src1x = imageUrlFor(heroImage).width(1152).auto('format').url()
    const srcSet = srcSetArrayToString([
      src1x,
      append2x(imageUrlFor(heroImage).width(1152).dpr(2).auto('format').url()),
      append3x(imageUrlFor(heroImage).width(1152).dpr(3).auto('format').url()),
    ])

    const mobileSrcSet = heroImageMobile
      ? srcSetArrayToString([
          imageUrlFor(heroImageMobile).width(575).auto('format').url(),
          append2x(imageUrlFor(heroImageMobile).width(575).dpr(2).auto('format').url()),
          append3x(imageUrlFor(heroImageMobile).width(575).dpr(3).auto('format').url()),
        ])
      : undefined

    return src1x ? (
      <picture>
        {mobileSrcSet && <source media="(max-width: 575px)" srcSet={mobileSrcSet} />}
        <img src={src1x} srcSet={srcSet} alt="" />
      </picture>
    ) : undefined
  }, [heroImage, heroImageMobile])

  const StyledTextBlock = (): JSX.Element => (
    <Styled.TextBlock $heroImageTextLayout={heroImageTextLayout} $heroTextStyles={heroTextStyles}>
      {titleDesktop && <Styled.LongTitle>{titleDesktop}</Styled.LongTitle>}
      {titleMobile && (
        <Styled.ShortTitle $heroImageTextLayout={heroImageTextLayout}>
          {titleMobile}
        </Styled.ShortTitle>
      )}
      {descriptionDesktopElement && <Styled.LongText>{descriptionDesktopElement}</Styled.LongText>}
      {descriptionMobileElement && (
        <Styled.ShortText $heroImageTextLayout={heroImageTextLayout}>
          {descriptionMobileElement}
        </Styled.ShortText>
      )}
      {cta && cta.link && cta.link.target && cta.link.target.length > 0 && (
        <Styled.Cta>
          <SanityLink to={cta.link} className="overlay-link" />
          <Styled.CtaButton
            as={SanityLink}
            to={cta.link}
            $ctaBackgroundColor={ctaBackgroundColor}
            $ctaTextColor={ctaTextColor}
          >
            {cta.title}
          </Styled.CtaButton>
        </Styled.Cta>
      )}
    </Styled.TextBlock>
  )

  return (
    <React.Fragment>
      {heroImageElement && heroImageTextLayout?.includes('background') && heroBackgroundImageFit ? (
        <>
          <Styled.SlideImageBackground $heroImageBackgroundFit={heroBackgroundImageFit}>
            {heroImageElement}
          </Styled.SlideImageBackground>
          <StyledTextBlock />
        </>
      ) : (
        heroImageElement && (
          <>
            <Styled.SlideImage $heroImageTextLayout={heroImageTextLayout}>
              {heroImageElement}
            </Styled.SlideImage>
            <StyledTextBlock />
          </>
        )
      )}
    </React.Fragment>
  )
}
