import { PrimaryButton } from '@designsforhealth/dfh-react-components'
import styled from 'styled-components'

import { backgroundStyles, BackgroundStylesMixin } from '../styles'

export const ProductSpotlight = styled.div<BackgroundStylesMixin>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${backgroundStyles};
  padding: 1rem;
  @media (min-width: 768px) {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  @media (min-width: 992px) {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
`
export const Header = styled.h2`
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-size: 28px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  width: 100%;
  @media (min-width: 1440px) {
    font-size: 36px;
  }
`

export const Description = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  position: relative;
  text-align: center;
  width: 100%;
  padding-bottom: 2.5rem;
  @media (min-width: 1440px) {
    font-size: 18px;
  }
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -12px;
  justify-content: center;
`

export const Column = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding: 12px;
  flex: 0 0 100%;
  max-width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
  @media (min-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
`

export const Product = styled.section`
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.coolGray.cool200};
  position: relative;
  flex: 1;
  width: 100%;
  @media (min-width: 1440px) {
    width: 628px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`

export const ImageAndText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ProductTitle = styled.h2`
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  margin-top: 1rem;
  margin-bottom: 0;
  font-weight: bold;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
  @media (min-width: 768px) {
    font-size: 24px;
  }
  @media (min-width: 992px) {
    font-size: 18px;
  }
  @media (min-width: 1440px) {
    font-size: 24px;
  }
`

export const ProductDescription = styled.div`
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-size: 12px;
  letter-spacing: 0.055em;
  color: ${({ theme }) => theme.colors.brand.gray};
  display: block;
  margin-top: 1rem;
  min-width: 172px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  @media (min-width: 992px) {
    font-size: 0.875rem;
  }
  @media (min-width: 1440px) {
    font-size: 18px;
    min-width: unset;
  }
}
`

export const ProductImageWrapper = styled.div`
  align-self: flex-start;
  width: 175px;
  @media (min-width: 425px) {
    width: 200px;
  }
  @media (min-width: 768px) {
    width: 300px;
  }
  @media (min-width: 992px) {
    width: 190px;
  }
  @media (min-width: 1440px) {
    width: 285px;
  }
`

export const ProductImage = styled.img`
  width: 100%;
  height: auto;
`

export const Text = styled.div`
  position: relative;
  max-width: 275px;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  @media (min-width: 1440px) {
    max-width: unset;
  }
`

export const Cta = styled(PrimaryButton)`
  font-size: 15px;
  --btn-height: 46px;
  margin-top: 1rem;
  background-color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
  width: 75%;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  @media (min-width: 992px) {
    font-size: 15px;
  }
  @media (min-width: 1440px) {
    font-size: 18px;
  }
`
