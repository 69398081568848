import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { getResourceAssetUrl, hasResourceAssetPage } from '../../../lib/library'
import { imageUrlFor } from '../../../lib/sanity'
import { libraryResourceIcon, libraryResourceName } from '../../../utils/libraryTools'
import Link from '../../global/Link'

const ResourceLink = React.memo(({ className, resource }) => {
  return hasResourceAssetPage(resource) ? (
    <Link className={className} to={getResourceAssetUrl(resource)}>
      <span className="resource-thumbnail">
        <img
          className="resource-icon"
          src={
            resource._type === 'socialMediaResource'
              ? imageUrlFor(resource.asset).width(120).height(120).dpr(2).auto('format').url()
              : libraryResourceIcon({ resourceType: resource._type })
          }
          alt={libraryResourceName({ resourceType: resource._type })}
        />
      </span>
      <span className="resource-title">{resource.title}</span>
    </Link>
  ) : (
    <a className={className} href={getResourceAssetUrl(resource)} target="_blank" rel="noreferrer">
      <span className="resource-thumbnail">
        <img
          className="resource-icon"
          src={libraryResourceIcon({ resourceType: resource._type })}
          alt={libraryResourceName({ resourceType: resource._type })}
        />
      </span>
      <span className="resource-title">{resource.title}</span>
    </a>
  )
})

ResourceLink.displayName = 'ResourceLink'

ResourceLink.defaultProps = {
  className: '',
}

ResourceLink.propTypes = {
  className: PropTypes.string,
  resource: PropTypes.object.isRequired,
}

const StyledResourceLink = styled(ResourceLink)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: '15px',
  overflow: 'hidden',
  border: '1px solid #ccc',
  color: '#02162f',
  '&:focus': {
    outline: '0 !important',
  },
  '&:hover': {
    boxShadow: '1px 3px 7px 0.5px rgba(11,11,11,.21)',
    textDecoration: 'underline',
    color: theme.colors.brand.orange,
  },
  '.resource-thumbnail': {
    display: 'flex',
    img: {
      display: 'block',
      maxWidth: '100%',
      margin: '16px auto',
      verticalAlign: 'middle',
    },
    '.resource-icon': {
      maxHeight: '120px',
    },
  },
  '.resource-title': {
    textAlign: 'center',
    display: '-webkit-box',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

export default StyledResourceLink
