import { PrimaryButton } from '@designsforhealth/dfh-react-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled, { css, StyledProps } from 'styled-components'

import { Maybe, SanitySiteColor } from '../../../../graphql/gatsby'
import ComingSoonImageImport from '../../../ComingSoonImage'
import SanityLink from '../../../global/SanityLink'
import {
  ctaBackgroundColorOrDefault,
  ctaTextColorOrDefault,
  fadeBackgroundInFromAbove,
} from '../../styles'

export const Card = styled.div`
  position: relative;
  display: flex;
  min-width: 0;
  word-wrap: break-word;
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  background-clip: border-box;
  border-radius: unset;
  border: 1px solid rgba(0, 0, 0, 0.125);
  min-height: 450px;

  @media (min-width: 992px) {
    min-height: unset;
    &:before {
      content: '';
      display: block;
      padding-top: 75%;
    }
  }
`

export const CardBackgroundImageTextLeft = styled(Card)``

export const CardImageAboveText = styled(Card)`
  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`

export const ImageToTextTransition = styled.div<{
  $descriptionBackgroundColor: Maybe<SanitySiteColor> | undefined
}>`
  width: 100%;
  position: relative;
  ${({ $descriptionBackgroundColor }) =>
    $descriptionBackgroundColor?.color &&
    fadeBackgroundInFromAbove(50, $descriptionBackgroundColor.color)};
  ${CardBackgroundImageTextLeft} & {
    display: none;
  }
  ${CardImageAboveText} & {
    @media (min-width: 992px) {
      display: none;
    }
  }
`

export const TextBlock = styled.div<{
  $descriptionBackgroundColor: Maybe<SanitySiteColor> | undefined
}>`
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  align-self: center;

  ${CardImageAboveText} & {
    padding-bottom: 4rem;
    min-height: 200px;
    ${({ $descriptionBackgroundColor }) =>
      $descriptionBackgroundColor?.color &&
      css`
        background-color: ${$descriptionBackgroundColor.color};
        @media (min-width: 992px) {
          background-color: unset;
        }
      `};
  }

  @media (min-width: 992px) {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    align-self: normal;
  }
`

export const OverlayLink = styled(SanityLink)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  transition: box-shadow 0.3s ease-out 0s;
  display: none;

  &:hover {
    box-shadow: rgb(11 11 11 / 21%) 1px 3px 7px 0.5px;
  }

  @media (min-width: 992px) {
    display: block;
  }
`

export const ImageWrapper = styled(GatsbyImage)<{
  $imageBackgroundColor: Maybe<SanitySiteColor> | undefined
}>`
  width: 100%;
  background-color: ${({ $imageBackgroundColor }) => $imageBackgroundColor?.color || 'transparent'};

  ${CardBackgroundImageTextLeft} & {
    // override inline style
    position: absolute !important;
    height: 100%;
  }
  ${CardImageAboveText} & {
    height: 431px;
    @media (min-width: 600px) {
      height: 508px;
    }
    @media (min-width: 768px) {
      height: 553px;
    }
    @media (min-width: 880px) {
      height: 633px;
    }
    @media (min-width: 992px) {
      // override inline style
      position: absolute !important;
      height: 100%;
    }
  }
  img {
    display: block;
  }
`

export const BlockMenuCtaLinksListEntry = styled.li`
  position: relative;
  width: 100%;
  min-height: 1px;
  display: block;
  box-sizing: border-box;
  padding-top: 7px;
  padding-bottom: 7px;
  @media (min-width: 576px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 7px 8px;
  }
  @media (min-width: 992px) {
    padding-left: 12px;
    padding-right: 12px;
    flex: 0 0 50%;
    max-width: 50%;
  }
`

const titleColorOrDefault = ({
  titleColor,
  theme,
}: StyledProps<{ titleColor: Maybe<SanitySiteColor | undefined> }>) =>
  titleColor?.color || theme.colors.brandBlue.dark100

export const ShortTitle = styled.h5<{
  titleColor: Maybe<SanitySiteColor> | undefined
}>`
  display: block;
  width: 60%;
  max-width: 60%;
  margin: 0 15%;
  z-index: 1;
  line-height: 1.1;
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: bold;
  font-size: 28px;
  text-align: left;
  letter-spacing: 0.025em;
  color: ${titleColorOrDefault};
  @media (min-width: 992px) {
    display: none;
  }
`

const descriptionColorOrDefault = ({
  descriptionColor,
  theme,
}: StyledProps<{ descriptionColor: Maybe<SanitySiteColor> | undefined }>) =>
  descriptionColor?.color || theme.colors.coolGray.cool900

export const ShortText = styled.p<{
  descriptionColor: Maybe<SanitySiteColor> | undefined
}>`
  display: block;
  width: 45%;
  max-width: 80%;
  margin: 0 15%;
  padding-top: 1rem;
  z-index: 1;
  &,
  p {
    text-align: left;
  }
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.055em;
  text-align: center;
  color: ${descriptionColorOrDefault};
  @media (min-width: 423px) {
    font-size: 13px;
  }
  @media (min-width: 768px) {
    width: 33%;
  }
  @media (min-width: 992px) {
    display: none;
  }
`

export const LongTitle = styled.h5<{
  titleColor: Maybe<SanitySiteColor> | undefined
}>`
  display: none;
  width: 80%;
  max-width: 80%;
  margin: 1.5rem 10% 0.75rem 10%;
  z-index: 1;
  line-height: 1.1;
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: bold;
  font-size: 26px;
  text-align: center;
  letter-spacing: 0.025em;
  color: ${titleColorOrDefault};
  @media (min-width: 992px) {
    display: block;
  }
  @media (min-width: 1200px) {
    font-size: 26px;
  }
  @media (min-width: 1440px) {
    font-size: 36px;
  }
`

export const LongText = styled.p<{ descriptionColor: Maybe<SanitySiteColor> | undefined }>`
  display: none;
  width: 80%;
  max-width: 80%;
  margin: 0 10%;
  z-index: 1;
  &,
  p {
    text-align: center;
  }
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.055em;
  text-align: center;
  color: ${descriptionColorOrDefault};
  @media (min-width: 992px) {
    display: block;
  }
  @media (min-width: 1200px) {
    font-size: 13px;
  }
  @media (min-width: 1440px) {
    font-size: 18px;
  }
`

export const ComingSoonImage = styled(ComingSoonImageImport)`
  display: block;
  position: absolute;
  margin-left: 5%;
  height: 65%;
  pointer-events: none;
  z-index: 1;
`

export const Cta = styled(PrimaryButton)<{
  $ctaBackgroundColor: Maybe<SanitySiteColor> | undefined
  $ctaTextColor: Maybe<SanitySiteColor> | undefined
}>`
  --btn-height: 46px;
  display: flex;
  align-items: center;
  font-size: 15px;
  justify-content: center;
  box-sizing: border-box;
  margin: 2rem 15% 0 15%;
  width: 75%;
  pointer-events: auto;
  cursor: pointer;
  color: ${ctaTextColorOrDefault};
  background-color: ${ctaBackgroundColorOrDefault};
  @media (min-width: 432px) {
    width: 50%;
  }
  @media (min-width: 600px) {
    width: 40%;
  }
  @media (min-width: 768px) {
    width: 33%;
  }
  @media (min-width: 992px) {
    display: none;
  }
`
