import { Button, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import React, { FC, MouseEventHandler } from 'react'

import { SetCurrentlyOpenTermsModal, TermsModalType } from '../types'

export interface TermsModalTriggerLinkProps {
  modalType: TermsModalType
  setCurrentlyOpenTermsModal: SetCurrentlyOpenTermsModal
  className?: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.body2,
    color: '#696969',
    // Ensure that the underline on the anchors does not push the text
    // down to make it sit below the non-link text:
    bottom: '1px',
    display: 'inline',
    height: 'inherit',
    letterSpacing: 'normal',
    padding: 0,
    textTransform: 'none',
  },
}))

export const TermsModalTriggerLink: FC<TermsModalTriggerLinkProps> = ({
  children,
  className,
  modalType,
  setCurrentlyOpenTermsModal,
}) => {
  const classes = useStyles()

  const handleOnClick: MouseEventHandler = (e) => {
    setCurrentlyOpenTermsModal(modalType)
    e.preventDefault()
  }

  return (
    <Button
      className={classnames(classes.root, className)}
      component={Link}
      onClick={handleOnClick}
      underline="always"
    >
      {children}
    </Button>
  )
}

TermsModalTriggerLink.defaultProps = {
  className: '',
}
