import styled from 'styled-components'

export const TableHeaderCell = styled.th`
  color: ${({ theme }) => theme.colors.grayscale.white};
  background-color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
  border: ${({ theme }) => `1px solid ${theme.colors.coolGray.cool500}`};
  text-align: left;
  vertical-align: middle;
  padding: 1rem;
`

export const TableCell = styled.td`
  border: ${({ theme }) => `1px solid ${theme.colors.coolGray.cool500}`};
  height: 60px;
  vertical-align: middle;
  padding: 1rem;
  max-width: 300px;
`

export const TableRow = styled.tr`
  height: 60px;
  vertical-align: middle;
  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.coolGray.cool150};
  }
`

export const Table = styled.table`
  table-layout: auto;
  width: 100%;
  p {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0;
  }
`
