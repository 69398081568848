import React from 'react'

import { SanityButtonBlockSection } from '../../../graphql/gatsby'
import { useSanityRoleBasedContent } from '../../../lib/sanity/hooks/useSanityRoleBasedContent'
import * as Styled from './styled'

export type ButtonBlockSectionProps = SanityButtonBlockSection

export const ButtonBlockSection: React.FC<ButtonBlockSectionProps> = ({
  roleBasedContent,
  pageSectionStyles,
  entries,
}) => {
  const { shouldDisplay } = useSanityRoleBasedContent({ roleBasedContent })
  if (!entries?.length || !shouldDisplay) {
    return null
  }

  return (
    <Styled.Container pageSectionStyles={pageSectionStyles}>
      <Styled.ButtonBlock>
        {entries.map(
          (entry) =>
            entry?.link && (
              <Styled.CtaButton to={entry.link} key={entry._key}>
                {entry.title}
              </Styled.CtaButton>
            ),
        )}
      </Styled.ButtonBlock>
    </Styled.Container>
  )
}
