import { withArtDirection } from 'gatsby-plugin-image'
import React, { useMemo } from 'react'
import { useId } from 'react-id-generator'

import { SanityBlockMenuLink } from '../../../../graphql/gatsby'
import { imageUrlFor } from '../../../../utils/image-url'
import { getGatsbyImageDataForAsset } from '../../../../utils/sanity/image'
import * as Styled from './styled'

export type BlockMenuLinkProps = SanityBlockMenuLink & {
  layout: string
}

export const BlockMenuLink: React.FC<BlockMenuLinkProps> = ({
  image,
  wideImage,
  title,
  description,
  link,
  comingSoon,
  layout,
}) => {
  const [headId, descId] = useId(2, 'bm-')

  const imageElement = useMemo((): JSX.Element | undefined => {
    const imageData = getGatsbyImageDataForAsset(image, {
      width: 550,
    })

    const wideImageData = getGatsbyImageDataForAsset(wideImage, {
      width: 618,
    })

    const images =
      imageData && wideImageData
        ? withArtDirection(imageData, [
            {
              image: imageData,
              media: '(max-width: 991px)',
            },
            {
              image: wideImageData,
              media: '(min-width: 992px)',
            },
          ])
        : imageData

    return images ? <Styled.ImageWrapper image={images} loading="eager" alt="" /> : undefined
  }, [image, wideImage])

  const showShortcardDeck = (backgroundLayout: string) =>
    backgroundLayout === 'white-bg' || backgroundLayout === 'dark-blue-bg'

  const blockMenuImage = image ? imageUrlFor(image).width(325).auto('format').url() : undefined

  return (
    <Styled.BlockMenuLinksListEntry aria-labelledby={headId}>
      {comingSoon && <Styled.ComingSoonImage />}
      <Styled.Card>
        {/* TODO: extract separate component for Additional Shortcut link and use image query */}
        {showShortcardDeck(layout) ? (
          <Styled.CardLinkShortcutDeck
            to={link || '/'}
            aria-labelledby={headId}
            aria-describedby={descId}
          >
            {blockMenuImage && (
              <picture>
                <Styled.ShortcardDeckImage src={blockMenuImage} loading="lazy" alt="" />
              </picture>
            )}
            <Styled.TextBlock>
              <Styled.TitleShortcardDeck id={headId}>{title}</Styled.TitleShortcardDeck>
              <Styled.TextShortcardDeck id={descId}>{description}</Styled.TextShortcardDeck>
            </Styled.TextBlock>
          </Styled.CardLinkShortcutDeck>
        ) : (
          <Styled.CardLink to={link || '/'} aria-labelledby={headId} aria-describedby={descId}>
            {imageElement}
            <Styled.Title id={headId}>{title}</Styled.Title>
            <Styled.Text id={descId}>{description}</Styled.Text>
          </Styled.CardLink>
        )}
      </Styled.Card>
    </Styled.BlockMenuLinksListEntry>
  )
}
