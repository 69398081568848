import React, { useMemo } from 'react'

import { SanityImageAndTextSection } from '../../../graphql/gatsby'
import { useSanityRoleBasedContent } from '../../../lib/sanity/hooks/useSanityRoleBasedContent'
import { getGatsbyImageDataForAsset } from '../../../utils/sanity/image'
import BodyPortableText from '../BodyPortableText'
import * as Styled from './styled'

export type ImageAndTextSectionProps = SanityImageAndTextSection

const ImageAndTextSection: React.FC<ImageAndTextSectionProps> = ({
  image,
  layout,
  textAlign,
  pageSectionStyles,
  subtitle,
  roleBasedContent,
  text,
  title,
}) => {
  const gatsbyImage = useMemo(() => getGatsbyImageDataForAsset(image, { width: 550 }), [image])
  const { shouldDisplay } = useSanityRoleBasedContent({ roleBasedContent })
  if (!shouldDisplay) {
    return null
  }
  return (
    <Styled.Container layout={layout} pageSectionStyles={pageSectionStyles}>
      {!!gatsbyImage && <Styled.Image image={gatsbyImage} alt="" />}
      <Styled.Text textAlign={textAlign}>
        <Styled.Header>
          {title && <Styled.Title>{title}</Styled.Title>}
          {subtitle && <Styled.Subtitle>{subtitle}</Styled.Subtitle>}
        </Styled.Header>
        {text && <BodyPortableText blocks={text} />}
      </Styled.Text>
    </Styled.Container>
  )
}

export default ImageAndTextSection
