import styled from 'styled-components'

import { SanityDispensaryFeaturedProducts } from '../../../graphql/gatsby'
import ProductBannerCardImport from '../../products/banners/cards/ProductBannerCard'
import { pageSectionStylesFor } from '../styles'

export const Container = styled.div<Pick<SanityDispensaryFeaturedProducts, 'pageSectionStyles'>>`
  background-color: ${({ theme }) => theme.colors.coolGray.cool150};
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  width: 100%;
  overflow: hidden; // https://macromedia.dreamweaver.narkive.com/qv8QEcss/div-not-expanding-height-to-fit-image-height-with-css
  ${({ pageSectionStyles }) => pageSectionStylesFor(pageSectionStyles)}
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  @media (min-width: 992px) {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
`

export const ProductList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  @media (min-width: 375px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

export const ProductBannerCard = styled(ProductBannerCardImport)`
  flex: none;
  max-width: none;
  width: 100%;

  @media (min-width: 540px) {
    width: calc(50% - 15px);
  }
  @media (min-width: 712px) {
    width: calc(33% - 15px);
  }
  @media (min-width: 1080px) {
    width: calc(25% - 15px);
  }
`
