import React from 'react'

import { SanityBlockMenuCta } from '../../../../graphql/gatsby'
import { useSanityRoleBasedContent } from '../../../../lib/sanity'
import { BlockMenuCtaLink } from '../BlockMenuCtaLink'
import * as Styled from './styled'

export type BlockMenuCtaProps = SanityBlockMenuCta

export const BlockMenuCta: React.FC<BlockMenuCtaProps> = ({
  sectionLabel,
  ariaLabel,
  links,
  roleBasedContent,
  backgroundStyles,
}) => {
  const { shouldDisplay } = useSanityRoleBasedContent({ roleBasedContent })
  if (!shouldDisplay) {
    return null
  }

  return (
    <Styled.BlockMenuCta $backgroundStyles={backgroundStyles}>
      <Styled.BlockMenuCtaContainer aria-label={ariaLabel || ''}>
        {sectionLabel && <Styled.Header>{sectionLabel}</Styled.Header>}
        <Styled.BlockMenuCtaLinksList>
          {links &&
            links.map((blockLink, index) => <BlockMenuCtaLink key={index} {...blockLink} />)}
        </Styled.BlockMenuCtaLinksList>
      </Styled.BlockMenuCtaContainer>
    </Styled.BlockMenuCta>
  )
}
