import { css, DefaultTheme, ThemedCssFunction } from 'styled-components'

// use negative margins to workaround lack of flexbox gap support in older browsers
export const flexboxGapStyles = (gap: string): ReturnType<ThemedCssFunction<DefaultTheme>> => css`
  --gap: ${gap};
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));
  > * {
    margin: var(--gap) 0 0 var(--gap);
  }
`

// another solution for lack of flexbox gap support with row and column gap
export const flexboxRowColumnGapStyles = (
  rowGap: string,
  columnGap: string,
): ReturnType<ThemedCssFunction<DefaultTheme>> => css`
  --row-gap: ${rowGap};
  --column-gap: ${columnGap};
  margin: calc(-1 * var(--column-gap)) 0 0 calc(-1 * var(--row-gap));
  width: calc(100% + var(--row-gap));
  > * {
    margin: var(--column-gap) 0 0 var(--row-gap);
  }
`
