import styled from 'styled-components'

import { flexboxRowColumnGapStyles } from '../../../lib/styles/flexbox-gap'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.brandBlue.light100};
  padding: 0 16px;
  // hide negative margin from CtaBlock
  overflow: hidden;
`

export const CtaBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 16px 0;
  ${flexboxRowColumnGapStyles('10%', '1rem')}
`
