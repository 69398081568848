import { PrimaryButton } from '@designsforhealth/dfh-react-components'
import React from 'react'
import styled from 'styled-components'

import { SanityCtaBlockSection } from '../../../graphql/gatsby'
import { useSanityRoleBasedContent } from '../../../lib/sanity'
import SanityLink from '../../global/SanityLink'
import * as Styled from './styled'

const StyledPrimaryButton = styled(PrimaryButton)`
  --btn-height: 46px;
  background-color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
  color: ${({ theme }) => theme.colors.grayscale.white};
  font-size: 15px;
  min-width: 280px;
`

export type CtaBlockSectionProps = SanityCtaBlockSection

export const CtaBlockSection: React.FC<CtaBlockSectionProps> = ({ roleBasedContent, entries }) => {
  const { shouldDisplay } = useSanityRoleBasedContent({ roleBasedContent })
  if (!entries?.length || !shouldDisplay) {
    return null
  }

  return (
    <Styled.Container>
      <Styled.CtaBlock>
        {entries.map(
          (entry) =>
            entry?.link && (
              <StyledPrimaryButton as={SanityLink} to={entry.link} key={entry._key}>
                {entry.title}
              </StyledPrimaryButton>
            ),
        )}
      </Styled.CtaBlock>
    </Styled.Container>
  )
}
