import React from 'react'
import styled from 'styled-components'

import { SanityCarousel } from '../../../graphql/gatsby'
import CarouselDeck from './CarouselDeck'
import { CarouselSlide, CarouselSlideWithComponent } from './types'

function isSlideWithComponent(value: CarouselSlide): value is CarouselSlideWithComponent {
  if (!value) return false
  const { carousel } = value
  if (!carousel) return false
  const [carouselComponent] = carousel
  return !!carouselComponent
}

export interface CarouselProps {
  className?: string
  deck: SanityCarousel['deck']
}

const Carousel: React.FC<CarouselProps> = ({ className, deck }) => {
  const carouselSlides = deck?.slides
  const filteredSlides = carouselSlides?.filter(isSlideWithComponent)
  if (!filteredSlides?.length) {
    return null
  }

  return (
    <section className={className}>
      <CarouselDeck slides={filteredSlides} />
    </section>
  )
}

const StyledCarousel = styled(Carousel)`
  --carousel-height: 269px;
  position: relative;

  @media (min-width: 576px) {
    --carousel-height: 285px;
  }
  @media (min-width: 768px) {
    --carousel-height: 316px;
  }
  @media (min-width: 992px) {
    --carousel-height: 297px;
  }
  @media (min-width: 1440px) {
    --carousel-height: 439px;
  }
`

export default StyledCarousel
