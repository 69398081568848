import { withArtDirection } from 'gatsby-plugin-image'
import React, { useMemo } from 'react'
import { useId } from 'react-id-generator'

import { SanityBlockMenuCtaLink } from '../../../../graphql/gatsby'
import { getGatsbyImageDataForAsset } from '../../../../utils/sanity/image'
import SanityLink from '../../../global/SanityLink'
import * as Styled from './styled'

export type BlockMenuCtaLinkProps = SanityBlockMenuCtaLink

type SanityCta = SanityBlockMenuCtaLink['cta']
type SanityCtaLink = NonNullable<SanityCta>['link']
type SanityCtaLinkTarget = NonNullable<SanityCtaLink>['target']
type SanityCtaLinkTargetEntry = NonNullable<SanityCtaLinkTarget>[number]
type SanityCtaWithValidLink = NonNullable<SanityBlockMenuCtaLink['cta']> & {
  link: NonNullable<SanityCtaLink> & { target: [SanityCtaLinkTargetEntry] }
}
const isCtaWithCompleteLink = (cta: SanityCta): cta is SanityCtaWithValidLink =>
  !!cta?.link?.target?.length

export const BlockMenuCtaLink: React.FC<BlockMenuCtaLinkProps> = ({
  mobileImage,
  tabletImage,
  desktopImage,
  imageBackgroundColor,
  title,
  titleColor,
  description,
  descriptionBackgroundColor,
  descriptionColor,
  comingSoon,
  cta,
  ctaBackgroundColor,
  ctaTextColor,
  layout,
}) => {
  const [headId, descId] = useId(2, 'bm-')

  const isImageAboveTextLayout = layout === 'imageAboveText'

  const imageElement = useMemo((): JSX.Element | undefined => {
    const mobileImageData = getGatsbyImageDataForAsset(mobileImage ?? tabletImage ?? desktopImage, {
      width: 576,
      height: 431,
      placeholder: 'blurred',
    })

    const tabletImageData = getGatsbyImageDataForAsset(tabletImage ?? desktopImage, {
      width: 991,
      height: 633,
      placeholder: 'blurred',
    })

    const desktopImageData = getGatsbyImageDataForAsset(desktopImage, {
      width: 926,
      height: 692,
      placeholder: 'blurred',
    })

    const images =
      mobileImageData && tabletImageData && desktopImageData
        ? withArtDirection(desktopImageData, [
            {
              image: mobileImageData,
              media: '(max-width: 576px)',
            },
            {
              image: tabletImageData,
              media: '(max-width: 991px)',
            },
            {
              image: desktopImageData,
              media: '(min-width: 992px)',
            },
          ])
        : desktopImageData

    return images ? (
      <Styled.ImageWrapper
        image={images}
        $imageBackgroundColor={imageBackgroundColor}
        loading="eager"
        alt=""
      />
    ) : undefined
  }, [desktopImage, imageBackgroundColor, mobileImage, tabletImage])

  const CardComponent = isImageAboveTextLayout
    ? Styled.CardImageAboveText
    : Styled.CardBackgroundImageTextLeft

  return (
    <Styled.BlockMenuCtaLinksListEntry aria-labelledby={headId}>
      {comingSoon && <Styled.ComingSoonImage />}
      <CardComponent>
        {imageElement}
        {isCtaWithCompleteLink(cta) && (
          <Styled.OverlayLink to={cta.link} aria-labelledby={headId} aria-describedby={descId} />
        )}
        <Styled.ImageToTextTransition $descriptionBackgroundColor={descriptionBackgroundColor} />
        <Styled.TextBlock $descriptionBackgroundColor={descriptionBackgroundColor}>
          <Styled.ShortTitle titleColor={titleColor}>{title}</Styled.ShortTitle>
          <Styled.ShortText descriptionColor={descriptionColor}>{description}</Styled.ShortText>
          {isCtaWithCompleteLink(cta) && (
            <Styled.Cta
              as={SanityLink}
              to={cta.link}
              $ctaBackgroundColor={ctaBackgroundColor}
              $ctaTextColor={ctaTextColor}
            >
              {cta.title}
            </Styled.Cta>
          )}
          <Styled.LongTitle id={headId} titleColor={titleColor}>
            {title}
          </Styled.LongTitle>
          <Styled.LongText id={descId} descriptionColor={descriptionColor}>
            {description}
          </Styled.LongText>
        </Styled.TextBlock>
      </CardComponent>
    </Styled.BlockMenuCtaLinksListEntry>
  )
}
