import { useLocation } from '@gatsbyjs/reach-router'
import queryString from 'query-string'
import { useEffect, useMemo } from 'react'

import {
  AllPharmacyLibraryResourcesDocument,
  AllProfessionalLibraryResourcesDocument,
  AllPublicLibraryResourcesDocument,
} from '../graphql/api'
import { useAuth } from '../lib/auth'
import { useSessionAuth } from '../lib/auth/hooks/useSessionAuth'
import { useUserAccessPolicy } from '../lib/auth/user-access-policy'
import { usePageUrl } from '../lib/pages'
import {
  defaultState as defaultSearchOptions,
  parseNameParam,
  parseTypeParam,
  professionalResourceTypes,
  publicResourceTypes,
} from '../utils/libraryTools'
import { userCategories } from '../utils/userClaims'
import { useApiQuery } from './useApiQuery'
import useUserCategory from './useUserCategory'

function parseQueryParamsIntoSearchOptions(
  defaultState,
  { type, name, query, page, size, sort, order },
) {
  return {
    ...defaultState,
    pagination: {
      ...defaultState.pagination,
      page: Number(page) || defaultState.pagination.page,
      size: Number(size) || defaultState.pagination.size,
    },
    sort: {
      ...defaultState.sort,
      field: sort || defaultState.sort.field,
      order: order || defaultState.sort.order,
    },
    filters: {
      ...defaultState.filters,
      type: parseTypeParam(type),
      name: parseNameParam(name),
      query: query || defaultState.filters.query,
    },
  }
}

function parseSearchOptionsIntoQueryVariables({ pagination, sort, filters }) {
  return {
    pagination: {
      offset: pagination.size * (pagination.page - 1),
      limit: pagination.size,
    },
    sort: {
      field: sort.field,
      order: sort.order,
    },
    filters: {
      type: filters.type,
      name: filters.name,
      searchKeyword: filters.query,
    },
  }
}

const initialState = { ...defaultSearchOptions }

const professionalCategories = [
  userCategories.employee,
  userCategories.practitioner,
  userCategories.otherProfessional,
]

const useResourceLibrary = () => {
  useSessionAuth()
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth()
  const { canReadPharmacyContent } = useUserAccessPolicy()
  const location = useLocation()
  const { navigate } = usePageUrl()

  const searchOptions = useMemo(() => {
    const queryParams = queryString.parse(location.search || '')
    return parseQueryParamsIntoSearchOptions(initialState, queryParams)
  }, [location.search])

  const { userCategory } = useUserCategory()
  const isProfessional = !!userCategory && professionalCategories.indexOf(userCategory) > -1
  const query =
    isProfessional && canReadPharmacyContent
      ? AllPharmacyLibraryResourcesDocument
      : isProfessional
        ? AllProfessionalLibraryResourcesDocument
        : AllPublicLibraryResourcesDocument

  const professionalTypeInFilter = useMemo(
    () =>
      searchOptions.filters.type.some(
        (type) => professionalResourceTypes.includes(type) && !publicResourceTypes.includes(type),
      ),
    [searchOptions.filters.type],
  )

  useEffect(() => {
    if (!professionalTypeInFilter || isLoading) {
      return
    }
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: { returnTo: location ? `${location.pathname}${location.search}` : '' },
      })
      return
    }
    if (!isProfessional && location) {
      navigate(location.pathname)
    }
  }, [
    isAuthenticated,
    isLoading,
    isProfessional,
    location,
    loginWithRedirect,
    navigate,
    professionalTypeInFilter,
  ])

  const variables = useMemo(
    () => parseSearchOptionsIntoQueryVariables(searchOptions),
    [searchOptions],
  )

  const { loading, data, error } = useApiQuery(query, {
    variables,
    skipWithoutToken: professionalTypeInFilter,
  })

  return {
    data:
      isProfessional && canReadPharmacyContent
        ? data?.allPharmacyLibraryResources
        : isProfessional
          ? data?.allProfessionalLibraryResources
          : data?.allPublicLibraryResources,
    loading,
    error,
    isProfessional,
    searchOptions,
  }
}

export default useResourceLibrary
