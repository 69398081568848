import BlockContent, {
  ListRendererProps,
  TypeSerializerProps,
} from '@sanity/block-content-to-react'
import React from 'react'

import * as Styled from './styled'

const BlockRenderer: React.FC<TypeSerializerProps> = (props) => {
  const { children, node } = props
  switch (node.style) {
    case 'h1':
      return <Styled.Heading1>{children}</Styled.Heading1>
    case 'h2':
      return <Styled.Heading2>{children}</Styled.Heading2>
    case 'blockquote':
      return <Styled.Blockquote>{children}</Styled.Blockquote>
    case 'normal':
      return <Styled.Paragraph>{children}</Styled.Paragraph>
    default:
      return BlockContent.defaultSerializers.types.block(props)
  }
}

const ListItemRenderer: React.FC = ({ children }) => {
  return <Styled.ListItem>{children}</Styled.ListItem>
}

const ListRenderer: React.FC<ListRendererProps> = ({ type, children }) => {
  return type === 'number' ? (
    <Styled.NumberedList>{children}</Styled.NumberedList>
  ) : (
    <Styled.BulletList>{children}</Styled.BulletList>
  )
}

export const serializers = {
  types: {
    block: BlockRenderer,
  },
  list: ListRenderer,
  listItem: ListItemRenderer,
}
