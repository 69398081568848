import { List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { ListRendererProps } from '@sanity/block-content-to-react'
import React, { FC } from 'react'

export const ListItemRenderer: FC = ({ children }) => {
  return (
    <ListItem>
      <ListItemText disableTypography>
        <Typography variant="body2">&bull; {children}</Typography>
      </ListItemText>
    </ListItem>
  )
}

export const ListRenderer: FC<ListRendererProps> = ({ children }) => {
  return <List disablePadding>{children}</List>
}
