import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Link from '../../global/Link'

const ResourceFiltersLink = ({ count, type, name, getLibraryUrl, filterLinkActive }) => {
  return count > 0 ? (
    <li className="facet-child">
      <Link
        to={filterLinkActive ? '/research-education/library' : getLibraryUrl({ types: [type] })}
        className={classNames('filter-link', { 'filter-link-visited': filterLinkActive })}
      >
        {filterLinkActive ? (
          <FontAwesomeIcon className="filter-link-icon" icon={faCheckSquare} />
        ) : (
          <FontAwesomeIcon className="filter-link-icon" icon={faSquare} />
        )}{' '}
        <div className="filter-link-text">
          {name}&nbsp;<span className="facet-count">({count})</span>
        </div>
      </Link>
    </li>
  ) : (
    ''
  )
}

ResourceFiltersLink.propTypes = {
  count: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  getLibraryUrl: PropTypes.func.isRequired,
  filterLinkActive: PropTypes.bool.isRequired,
}

export default ResourceFiltersLink
