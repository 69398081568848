import { PrimaryButton } from '@designsforhealth/dfh-react-components'
import styled from 'styled-components'

import { SanityImageAndTextSection } from '../../../graphql/gatsby'
import { pageSectionStylesFor } from '../styles'

export const Container = styled.div<Pick<SanityImageAndTextSection, 'pageSectionStyles'>>`
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  ${({ pageSectionStyles }) => pageSectionStylesFor(pageSectionStyles)};
  @media (min-width: 768px) {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  @media (min-width: 992px) {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
`

export const Card = styled.div`
  width: 100%;
  background-color: #eff7ff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row;
  gap: 10px;
  align-items: center;
  justify-items: center;
  border-radius: 10px;
  padding: 20px 0;
`

export const CardTitle = styled.h4`
  font-size: 1rem;
  padding-top: 12px;
  margin-right: auto;
  margin-left: 2rem;
  @media (min-width: 576px) {
    font-size: 1.125rem;
  }
`

export const CtaButton = styled(PrimaryButton)`
  margin-left: auto;
  margin-right: 2rem;
`
