import React from 'react'

import { SanityIconAndText, SanityIconAndTextGrid } from '../../../graphql/gatsby'
import { useSanityRoleBasedContent } from '../../../lib/sanity/hooks/useSanityRoleBasedContent'
import { imageUrlFor } from '../../../utils/image-url'
import * as Styled from './styled'

export interface IconAndTextProps extends SanityIconAndText {
  index: number
}

const IconAndText: React.VFC<IconAndTextProps> = ({ index, title, icon, text }) => {
  const sanityIconUrl = icon ? imageUrlFor(icon).width(74).height(74).url() : undefined
  return (
    <>
      {sanityIconUrl && (
        <Styled.IconWrapper $index={index}>
          <Styled.Icon src={sanityIconUrl} alt="" />
        </Styled.IconWrapper>
      )}
      {title && <Styled.IconTitle $index={index}>{title}</Styled.IconTitle>}
      {text && <Styled.Text blocks={text} $index={index} />}
    </>
  )
}

export type IconAndTextGridProps = SanityIconAndTextGrid

export const IconAndTextGrid: React.VFC<IconAndTextGridProps> = ({
  title,
  roleBasedContent,
  entries,
  pageSectionStyles,
}) => {
  const { shouldDisplay } = useSanityRoleBasedContent({ roleBasedContent })
  if (!entries || !shouldDisplay) {
    return null
  }
  return (
    <Styled.Container pageSectionStyles={pageSectionStyles}>
      {title && <Styled.Title>{title}</Styled.Title>}
      <Styled.IconGroup>
        {entries.map((iconWithText, index) => {
          return (
            iconWithText && (
              <IconAndText {...iconWithText} index={index} key={iconWithText._key ?? index} />
            )
          )
        })}
      </Styled.IconGroup>
    </Styled.Container>
  )
}
