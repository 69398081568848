import React from 'react'

import { TermsLinkComponent } from '../types'
import { TermsModalTriggerLink } from './TermsModalTriggerLink'

export const PrivacyNoticeLink: TermsLinkComponent = ({
  className,
  setCurrentlyOpenTermsModal,
  text,
}) => (
  <TermsModalTriggerLink
    className={className}
    modalType="privacyNotice"
    setCurrentlyOpenTermsModal={setCurrentlyOpenTermsModal}
  >
    {text}
  </TermsModalTriggerLink>
)

PrivacyNoticeLink.defaultProps = {
  className: '',
  text: 'Privacy Notice',
}
