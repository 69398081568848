import React from 'react'

import { SanityIconAndText, SanityIconAndTextGridSmall } from '../../../graphql/gatsby'
import { useSanityRoleBasedContent } from '../../../lib/sanity/hooks/useSanityRoleBasedContent'
import { imageUrlFor } from '../../../utils/image-url'
import * as Styled from './styled'

export type IconAndTextProps = SanityIconAndText

const IconAndText: React.VFC<IconAndTextProps> = ({ title, icon }) => {
  const sanityIconUrl = icon ? imageUrlFor(icon).width(70).height(70).url() : undefined
  return (
    <Styled.IconWithTitle>
      {title && <Styled.IconTitle>{title}</Styled.IconTitle>}
      {sanityIconUrl && <Styled.Icon src={sanityIconUrl} alt="" />}
    </Styled.IconWithTitle>
  )
}

export type IconAndTextGridSmallProps = SanityIconAndTextGridSmall

export const IconAndTextGridSmall: React.VFC<IconAndTextGridSmallProps> = ({
  roleBasedContent,
  entries,
  pageSectionStyles,
}) => {
  const { shouldDisplay } = useSanityRoleBasedContent({ roleBasedContent })
  if (!entries || !shouldDisplay) {
    return null
  }
  const numEntries = entries.length

  return (
    <Styled.Container pageSectionStyles={pageSectionStyles}>
      <Styled.IconGroup numEntries={numEntries}>
        {entries.map((iconWithText, index) => {
          return iconWithText && <IconAndText {...iconWithText} key={iconWithText._key ?? index} />
        })}
      </Styled.IconGroup>
    </Styled.Container>
  )
}
