import styled from 'styled-components'

import { Card } from '../BlockMenuLink/styled'

export const Header = styled.h2`
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: bold;
  font-size: 1.625rem;
  color: ${({ theme }) => theme.colors.component.textDark};
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  padding-top: 2rem;
`

export const BlockMenuContainer = styled.nav`
  position: relative;
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 2px 0;
  box-sizing: border-box;
  ${Card} {
    margin: 2px 0 0;
  }
  @media (min-width: 432px) {
    padding: 14px 16px;
  }
  @media (min-width: 992px) {
    padding: 18px 16px;
    &:not(.lg-2-cols),
    &.lg-3-cols {
      ul {
        li {
          flex: 0 0 33.3%;
          max-width: 33.3%;
        }
      }
    }
    &.lg-2-cols {
      ${Card} {
        &:before {
          padding-top: 52%;
        }
      }
    }
  }
`

export const BlockMenuLinksList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  justify-content: center;
  list-style: none;
  @media (min-width: 432px) {
    margin-top: -5px;
    margin-bottom: -5px;
  }
  @media (min-width: 576px) {
    margin: -7px -8px;
  }
  @media (min-width: 992px) {
    margin-left: -8px;
    margin-right: -8px;
  }
`
