import React from 'react'

import { SanityBlockMenu } from '../../../../graphql/gatsby'
import { useSanityRoleBasedContent } from '../../../../lib/sanity'
import { BlockMenuLink } from '../BlockMenuLink'
import * as Styled from './styled'

export type BlockMenuProps = SanityBlockMenu

export const BlockMenu: React.FC<BlockMenuProps> = ({
  sectionLabel,
  ariaLabel,
  layout,
  links,
  roleBasedContent,
}) => {
  const { shouldDisplay } = useSanityRoleBasedContent({ roleBasedContent })
  if (!shouldDisplay) {
    return null
  }

  return (
    <Styled.BlockMenuContainer className={layout || ''} aria-label={ariaLabel || ''}>
      {sectionLabel && <Styled.Header>{sectionLabel}</Styled.Header>}
      <Styled.BlockMenuLinksList>
        {links &&
          links.map((blockLink, index) => (
            <BlockMenuLink key={index} layout={layout || ''} {...blockLink} />
          ))}
      </Styled.BlockMenuLinksList>
    </Styled.BlockMenuContainer>
  )
}
