import { Typography } from '@material-ui/core'
import type { TypeSerializerProps } from '@sanity/block-content-to-react'
import React, { FC } from 'react'

export const BlockRenderer: FC<TypeSerializerProps> = ({ children, node }) => {
  const { style } = node
  switch (style) {
    case 'normal':
      return (
        <Typography variant="body2" gutterBottom>
          {children}
        </Typography>
      )
    case 'h1':
      return (
        <Typography variant="h1" gutterBottom>
          {children}
        </Typography>
      )
    case 'h2':
      return (
        <Typography variant="h2" gutterBottom>
          {children}
        </Typography>
      )
    case 'h3':
      return (
        <Typography variant="h3" gutterBottom>
          {children}
        </Typography>
      )
    case 'h4':
      return (
        <Typography variant="h4" gutterBottom>
          {children}
        </Typography>
      )
    case 'h5':
      return (
        <Typography variant="h5" gutterBottom>
          {children}
        </Typography>
      )
    case 'h6':
      return (
        <Typography variant="h6" gutterBottom>
          {children}
        </Typography>
      )
    case 'blockquote':
      return (
        <Typography component="blockquote" variant="body2" gutterBottom>
          {children}
        </Typography>
      )
    default:
      return <div>{children}</div>
  }
}
