import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, { useMemo } from 'react'

import { SanityFeaturedProductsCarousel } from '../../../graphql/gatsby'
import { useSanityRoleBasedContent } from '../../../lib/sanity'
import { FeaturedProductEntries } from '../FeaturedProductEntries'
import { FeaturedProductsSlider } from '../FeaturedProductsSlider'
import * as Styled from './styled'

type ProductDetails = {
  sku: string
  tagline: string
  shortDescription: string
}

const FeaturedProductsCarousel: React.FC<SanityFeaturedProductsCarousel> = ({
  title,
  subtitle,
  products,
  roleBasedContent,
  backgroundColor,
  ctaBackgroundColor,
  cta,
  ctaTextColor,
  textColor,
}) => {
  const { shouldDisplay } = useSanityRoleBasedContent({ roleBasedContent })
  const productsDetails = useMemo(
    () =>
      products
        ?.map((upsellProduct): ProductDetails => {
          return {
            sku: upsellProduct?.sku || '',
            tagline: upsellProduct?.tagline || '',
            shortDescription: upsellProduct?.shortDescription || '',
          }
        })
        .filter(Boolean) || [],
    [products],
  )

  const isMobile = useMediaQuery('(max-width:1080px)')

  if (!shouldDisplay || !productsDetails) {
    return null
  }

  return (
    <Styled.Background backgroundColor={backgroundColor?.color || undefined}>
      <Styled.Container>
        <Styled.Wrapper>
          <Styled.Title textColor={textColor?.color || undefined}>
            {title ?? 'Featured Products'}
          </Styled.Title>
          {subtitle && (
            <Styled.Subtitle textColor={textColor?.color || undefined}>{subtitle}</Styled.Subtitle>
          )}
          {!isMobile && cta && cta.link && (
            <Styled.Button
              to={cta.link}
              $ctaBackgroundColor={ctaBackgroundColor?.color || undefined}
              $ctaTextColor={ctaTextColor?.color || undefined}
              $isMobile={isMobile}
            >
              {cta.title ?? 'Explore all featured'}
            </Styled.Button>
          )}
        </Styled.Wrapper>
        {!isMobile && (
          <FeaturedProductsSlider
            productsDetails={productsDetails}
            config={{
              dots: true,
              slidesToScroll: 3,
              slidesToShow: 3,
            }}
          />
        )}
        {isMobile && (
          <>
            <Styled.ScrollableContainer>
              <FeaturedProductEntries productsDetails={productsDetails} />
            </Styled.ScrollableContainer>
            {cta && cta.link && (
              <Styled.Button
                to={cta.link}
                $ctaBackgroundColor={ctaBackgroundColor?.color || undefined}
                $ctaTextColor={ctaTextColor?.color || undefined}
                $isMobile={isMobile}
              >
                {cta.title ?? 'Explore all featured'}
              </Styled.Button>
            )}
          </>
        )}
      </Styled.Container>
    </Styled.Background>
  )
}

export default FeaturedProductsCarousel
