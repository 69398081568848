import { useQuery } from '@apollo/client'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import React, { useContext, useMemo } from 'react'

import { SearchExactParentOrChildSkuDocument } from '../../../graphql/search'
import useLogAndCaptureError from '../../../hooks/useLogAndCaptureError'
import { useRefetchOnChange } from '../../../hooks/useRefetchOnChange'
import { TokenContext } from '../../../layouts/context'
import { ProductDetails } from '../../../lib/products/modal/types'
import Spinner from '../../Spinner'
import * as Styled from './styled'

type SliderConfigBreakpoint = {
  breakpoint: number
  settings: {
    slidesToShow: number
  }
}

type SliderConfig = {
  dots: boolean
  slidesToShow: number
  slidesToScroll: number
  responsive?: SliderConfigBreakpoint[]
}

interface FeaturedProductsSliderProps {
  productsDetails: ProductDetails[]
  config?: SliderConfig
}

type ArrowProps = {
  className?: string
  style?: React.CSSProperties
  onClick?: () => void
}

const SliderPrevArrow: React.FC<ArrowProps> = ({ className, onClick, style }) => {
  return (
    <Styled.ArrowContainer className={className} onClick={onClick} style={style}>
      <ArrowBackIos htmlColor="gray" viewBox="0 0 18 24" />
    </Styled.ArrowContainer>
  )
}
const SliderNextArrow: React.FC<ArrowProps> = ({ className, onClick, style }) => {
  return (
    <Styled.ArrowContainer className={className} onClick={onClick} style={style}>
      <ArrowForwardIos htmlColor="gray" viewBox="0 0 24 24" />
    </Styled.ArrowContainer>
  )
}

export const FeaturedProductsSlider: React.FC<FeaturedProductsSliderProps> = ({
  productsDetails,
  config,
}) => {
  const token = useContext(TokenContext)
  const productsSkus = productsDetails?.map((product) => product.sku)
  const { data, loading, error, refetch, networkStatus } = useQuery(
    SearchExactParentOrChildSkuDocument,
    {
      context: {
        token,
        uri: process.env.GATSBY_SEARCH_URL,
      },
      variables: {
        sku: productsSkus,
      },
      notifyOnNetworkStatusChange: true,
      skip: !productsSkus || productsSkus.length === 0,
    },
  )
  useRefetchOnChange({ networkStatus, refetch }, [token])
  useLogAndCaptureError(error)

  const products = data?.exact?.results
  const sortedProducts = useMemo(() => {
    const skuOrder = productsDetails.map((product) => product.sku)
    if (Array.isArray(products)) {
      return [...products].sort((a, b) => skuOrder.indexOf(a.sku) - skuOrder.indexOf(b.sku))
    }
  }, [products, productsDetails])

  const sliderConfig = config ?? {
    dots: false,
    slidesToShow: 6,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1880,
        settings: {
          slidesToShow: 5,
        },
      },
    ],
  }

  if (loading) {
    return <Spinner loading />
  }

  if (!sortedProducts) return null

  return (
    <Styled.Slider
      swipe
      speed={500}
      infinite={false}
      initialSlide={0}
      prevArrow={<SliderPrevArrow />}
      nextArrow={<SliderNextArrow />}
      {...sliderConfig}
    >
      {sortedProducts.map((product, index) => (
        <Styled.ProductBannerCard
          productDetails={productsDetails[index]}
          product={product}
          key={product.sku}
          analyticsEventAppContext="featured_products"
        />
      ))}
    </Styled.Slider>
  )
}
