import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import Link from '../../global/Link'
import { LinkPrimaryButton } from '../../global/LinkButton'

export const DispensaryHero = styled.div`
  overflow: hidden;
`

export const HeroImageWithTextCallToAction = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
`

export const InnerContainer = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
`

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

export const Paragraph = styled.strong<{
  $textColor: string
}>`
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-weight: bold;
  color: ${(props) => props.$textColor};
`

export const CallToActionImageLink = styled(Link)`
  display: block;
  width: 100%;
`

export const CallToActionImage = styled(GatsbyImage)``

export const Button = styled(LinkPrimaryButton)<{
  $ctaBackgroundColor: string
  $ctaTextColor: string
}>`
  background-color: ${(props) => props.$ctaBackgroundColor};
  color: ${(props) => props.$ctaTextColor};
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
`
