import styled from 'styled-components'

export const Notification = styled.div`
  background-color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
  overflow: hidden;
  width: 100%;
`

export const MaxWidthContainer = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`

export const NotificationPanel = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.coolGray.cool550};
  border-bottom: 1px solid ${({ theme }) => theme.colors.coolGray.cool550};
  color: ${({ theme }) => theme.colors.grayscale.white};
  padding: 20px 15px;
  @media (min-width: 576px) {
    margin: 20px 0;
  }
`

export const Title = styled.h3`
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 16px;
`

export const Content = styled.div`
  p {
    color: ${({ theme }) => theme.colors.grayscale.white};
    text-align: center;
    font-size: 15px;
    font-family: ${({ theme }) => theme.fontFamilies.gotham};
    margin-bottom: 1rem;
    margin-top: 0;
    line-height: 1.5;
    &:last-child {
      margin-bottom: 0;
    }
  }
`
