import React, { FC } from 'react'

export const ComponentNotFound = (type: string): FC => {
  const ComponentNotFoundComponent: FC = () => {
    console.error(`Component ${type} is not defined. Add it to SanityPageComponents`)
    return (
      <div style={{ background: '#fff' }}>
        Component {type} is not defined. Add it to SanityPageComponents
      </div>
    )
  }
  ComponentNotFoundComponent.displayName = `ComponentNotFound(${type})`
  return ComponentNotFoundComponent
}
